import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner'

export const LoaderContext = React.createContext({
  isLoading: false,
  setIsLoading: () => {},
})

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading && (<div style={{
        zIndex: 99999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#222A3F',
        opacity: 0.75
      }}>
        <div style={{textAlign: 'center'}}>
          <span style={{fontSize: 14, fontWeight: 'bold', color: 'white'}}>Aguarde<br />processando...</span>
          <br />&nbsp;<br />
          <Spinner animation="border" role="status" variant='primary' color='white'>
          </Spinner>
        </div>
      </div>)}
      {children}
    </LoaderContext.Provider>
  )
}
