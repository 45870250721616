import React from 'react';
import { Carousel, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.css';

export default function Slider() {
  return (
    <Container  fluid="md" className="mb-5 carousel-wrapper">
      <Row>
        <div className="">
          <Carousel style={{padding: '0 1vw'}} interval={8000} indicators={false}>
          <Carousel.Item>
              <div className="d-none d-md-block">
                <a href="https://www.atusmkt.com.br/experienciasw" target="_blank">
                  <img
                    className="d-block w-100"
                    src="imagens/banners/pintoupremiodesktop.png"
                    alt="Slider"
                  />
                </a>
              </div>
              <div className="d-block d-md-none">
                <a href="https://www.atusmkt.com.br/experienciasw" target="_blank">
                  <img
                    className="d-block w-100"
                    src="imagens/banners/pintoumobile.png"
                    alt="Slider"
                  />
                </a>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-none d-md-block">
                <Link>
                  <img
                    className="d-block w-100"
                    src="imagens/banners/black_destop.png"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-block d-md-none">
                <Link>
                  <img
                    className="d-block w-100"
                    src="imagens/banners/black_mobile.png"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-none d-md-block">
                <Link>
                  <img
                    className="d-block w-100"
                    src="imagens/banners/banner-experiencias-sw.png"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-block d-md-none">
                <Link>
                  <img
                    className="d-block w-100"
                    src="imagens/banners/banner-experiencias-sw-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-none d-md-block">
                <Link>
                  <img
                    className="d-block w-100"
                    src="imagens/banners/banner1.PNG"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-block d-md-none">
                <Link>
                  <img
                    className="d-block w-100"
                    src="imagens/banners/banner1-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-none d-md-block">
                <Link>
                  <img
                    className="d-block w-100"
                    src="imagens/banners/banner2.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-block d-md-none">
                <Link>
                  <img
                    className="d-block w-100"
                    src="imagens/banners/banner2-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>





          </Carousel>
        </div>
      </Row>
    </Container>
  );
}
