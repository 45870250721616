import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { desautenticarParceiro } from '../../../store/modules/autenticacao/actions';
import Nav from '../../../components/Loja/Nav';
import NavMobile from '../../../components/Loja/NavMobile';
import ModalVideoWrapper from '../ModalVideoWrapper';
import { atualizarSaldo } from '../../../store/modules/parceiro/actions';

export default function Header(props) {
  const dispatch = useDispatch();
  const parceiro = useSelector((state) => state.parceiro);
  const autenticado = useSelector((state) => state.autenticacao.autenticadoLoja);

  const [estadoModal, setEstadoModal] = useState(false);

  useEffect(() => {
    if (autenticado && parceiro.cpf) {
      dispatch(atualizarSaldo(parceiro.cpf));
    }
  }, [dispatch, parceiro.cpf, autenticado]);

  const sair = () => {
    dispatch(desautenticarParceiro());
  };

  return (
    <header
      className={props.auto ? 'header-home-auto' : 'header-home'}
      style={{ paddingBottom: 32 }}
    >
      <Container className="d-flex flex-column justify-content-center align-items-center text-center h-100">
        {!autenticado ? (
          <Row className="w-100 my-2" style={{ gap: 8 }}>
            <Col className="d-flex align-items-center justify-content-center">
              <Link to="/loja" className="d-flex flex-column">
                {/* <img
                  style={{ width: '140px', marginLeft: '-30px' }}
                  src="/imagens/logo-fundo-claro.png"
                  alt="Logo Clube Pro Pintor"
                ></img> */}
                {/* <span className="f-10">Clube de Fidelidade</span> */}
                <img
                  className="w-100 nav-logo m-auto"
                  src="/imagens/logo-experiencias-sw-branco.png"
                  alt="Logo Clube Pro Pintor"
                ></img>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <span className="f-10">Administrado e Desenvolvido por</span>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      className="nav-brand m-auto"
                      src="/imagens/logo-smart.svg"
                      alt="Logo Smart"
                    ></img>
                    <span className="f-10 text-nowrap">
                      Clube de Fidelidade
                    </span>
                  </div>
                </div>
              </Link>
            </Col>
            <Col className="header-acoes">
              <Link to="login" className="link-colored mr-3">
                <i className="fas fa-user mr-1"></i> ENTRAR
              </Link>
              <Link to="cadastrar" className="border-colored mr-3">
                <i className="fas fa-user-edit mr-1"></i> CADASTRE-SE
              </Link>
              {/*
               <Link to="relatorio" className="border-colored mr-3">
                <i className="fas fa-store mr-1"></i> LOJISTA
              </Link> */}
              <button
                onClick={() => setEstadoModal(true)}
                className="border-colored p-1"
              >
                <i className="fas fa-video mr-1"></i> TUTORIAL
              </button>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <img src="/imagens/sw-logo.png" width={150} alt="Logo SW" />
            </Col>
          </Row>
        ) : (
          <>
            <Row className="d-flex d-md-none w-100">
              <NavMobile />
            </Row>
            <Row className="w-100 p-3">
              <div className="d-none d-md-block">
                <Link to="/home" style={{ fontSize: 10 }}>
                  <img
                    className=" nav-logo"
                    src="/imagens/logo-experiencias-sw-branco.png"
                    alt="Logo Experiências SW"
                  ></img>
                  <div className="d-none d-md-flex flex-column justify-content-center align-items-center">
                    <span>Administrado e Desenvolvido por</span>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <img
                        className="nav-brand m-auto"
                        src="/imagens/logo-smart.svg"
                        alt="Logo Smart"
                      ></img>
                      <span className="f-10 text-nowrap">
                        Clube de Fidelidade
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="m-auto">
                <span>
                  Olá, <b>{parceiro.nome}</b>! Seu saldo atual é de{' '}
                  <b>{Number(parceiro.saldoPontos).toLocaleString('pt-BR')}</b>{' '}
                  pontos.
                </span>
              </div>
              <div className="d-flex align-items-center ">
                <Link className="nav-indicacao" to="/indicacao">
                  INDIQUE E GANHE
                </Link>
              </div>
              <div
                className="mx-auto my-4 d-flex flex-column justify-content-between align-items-center"
                style={{ gap: '1rem' }}
              >
                <div className="d-none d-md-flex justify-content-center align-items-center">
                  <img src="/imagens/sw-logo.png" width={150} alt="Logo SW" />
                </div>
                <button onClick={sair} className="nav-sair">
                  <i className="fas fa-sign-out-alt" /> Sair
                </button>
              </div>
            </Row>
            <Row className="d-none d-md-flex w-100">
              <Nav />
            </Row>
          </>
        )}
      </Container>
      <ModalVideoWrapper
        show={estadoModal}
        onHide={() => setEstadoModal(false)}
      >
        <video
          autoPlay
          autoStart
          controls
          height={700}
          width={'auto'}
          src="https://smart-content.sfo3.cdn.digitaloceanspaces.com/experiencias-sw/tutorial.mp4"
        ></video>
      </ModalVideoWrapper>
    </header>
  );
}
