import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';

const { SearchBar } = Search;

export default function TaxaSucesso(props) {
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
      formatter: (celula, valor) => {
        return `${valor.rede}${valor.codProduto}`;
      },
    },
    {
      dataField: 'rede',
      text: 'Rede',
      sort: true,
      footer: (columnData) => null,
    },
    {
      dataField: 'produto',
      text: 'Produto',
      sort: true,
      footer: (columnData) => null,
    },
    {
      dataField: 'codProduto',
      text: 'Cód. Experiências SW',
      sort: true,
      footer: (columnData) => null,
    },
    {
      dataField: 'valorUnitarioProduto',
      text: 'Valor Unitário',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.valorUnitarioProduto)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
      footer: (columnData) => null,
    },
    {
      dataField: 'mesRef',
      text: 'Mês Ref.',
      sort: true,
      formatter: (celula, valor) => {
        return valor.mes + '/' + valor.ano;
      },
      footer: (columnData) => null,
    },
    {
      dataField: 'qtdVendidaAntes',
      text: 'Qtd. Vendida Antes',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.qtdVendidaAntes).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
      footer: (columnData) => null,
    },
    {
      dataField: 'mesesAtendidoAntes',
      text: 'Meses Atendidos Antes',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.mesesAtendidoAntes).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
      footer: (columnData) => null,
    },
    {
      dataField: 'mediaMensal',
      text: 'Média Mensal',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.mediaMensal).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
      footer: (columnData) => null,
    },
    {
      dataField: 'qtdVendida',
      text: 'Quantidade Vendida',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.qtdVendida).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
      footer: (columnData) => null,
    },
    {
      dataField: 'aumentoVendas',
      text: 'Aumento Vendas',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.aumentoVendas).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
      footer: (columnData) => null,
    },
    {
      dataField: 'aumentoVendasDinheiro',
      text: 'R$ Aumento Vendas',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.aumentoVendasDinheiro)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
      footer: (columnData) => 'Total',
    },
    {
      dataField: 'taxaSucesso',
      text: 'Taxa de Sucesso',
      sort: true,
      formatter: (celula, valor) => {
        return `${Number(valor.taxaSucesso)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ')}`;
      },
      sortFunc: sortFunc,
      footer: (columnData) =>
        data
          .reduce((acc, item) => Number(acc) + Number(item.taxaSucesso), 0)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' '),
    },
  ];

  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  useEffect(() => {
    buscarRedes();
  }, []);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  const buscarTaxaSucesso = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/taxa-sucesso?rede=${redeSelecionada}&ano=${anoSelecionado}&mes=${mesSelecionado
        .toString()
        .padStart(2, '0')}`
    );
    if (resultado) {
      setData(resultado.data.taxaSucesso);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState(
    new Date().getFullYear()
  );
  const [redeSelecionada, setRedeSelecionada] = useState('');
  const [mesSelecionado, setMesSelecionado] = useState(moment().month());
  const [redesSelect, setRedesSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Taxa de Sucesso</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="anoSelecionado">Ano</label>
          <select
            className="input-select mb-3"
            name="anoSelecionado"
            id="anoSelecionado"
            onChange={(e) => setAnoSelecionado(e.target.value)}
            defaultValue={anoSelecionado}
          >
            {(() => {
              let anosSelect = [];
              for (let l = 2020; l <= moment().year(); l++) {
                anosSelect.push(<option value={l}>{l}</option>);
              }
              return anosSelect;
            })()}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="mesSelecionado">Mês</label>
          <select
            className="input-select mb-3"
            name="mesSelecionado"
            id="mesSelecionado"
            onChange={(e) => setMesSelecionado(e.target.value)}
            defaultValue={mesSelecionado}
          >
            {(() => {
              let mesesSelect = [];
              if (anoSelecionado === 2020) {
                for (var l = 7; l <= moment().month(); l++) {
                  mesesSelect.push(<option value={l}>{meses[l - 1]}</option>);
                }
              } else {
                for (var j = 1; j <= moment().month(); j++) {
                  mesesSelect.push(<option value={j}>{meses[j - 1]}</option>);
                }
              }
              return mesesSelect;
            })()}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button
            className="btn-busca"
            type="submit"
            onClick={buscarTaxaSucesso}
          >
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
