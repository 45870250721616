import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './styles.css';
import ModalDesbloquearCartao from '../../Painel/ModalDesbloquearCartao/ModalDesbloquearCartao';
import moment from 'moment-timezone';
import ModalBloquearCartao from '../../Painel/ModalBloquearCartao/ModalBloquearCartao';
import { Link } from 'react-router-dom';

export default function Cartao() {
  const cartao = useSelector((state) => {
    return state.parceiro.cartao;
  });

  const [mostrarModalDesbloquearCartao, setMostrarModalDesbloquearCartao] =
    useState(false);
  const [mostrarModalBloquearCartao, setMostrarModalBloquearCartao] =
    useState(false);

  const fecharModalDesbloquearCartao = () => {
    setMostrarModalDesbloquearCartao(false);
  };

  const fecharModalBloquearCartao = () => {
    setMostrarModalBloquearCartao(false);
  };

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>CARTÃO</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <Row>
        <Col>
          <Link to="/loja/credito">
            <img
              className="w-100"
              src="/imagens/banner_cartao.jpg"
              alt="Logo cartão"
            ></img>
          </Link>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col xs={12} lg={4} className="mt-3 mb-3">
          {cartao && cartao.numero && (
            <div className="card-cartao">
              <span className="card-cartao-titulo">
                <i className="fas fa-credit-card mr-2" />
                Saldo
              </span>
              <span className="card-cartao-subtitulo">
                {parseFloat(cartao.saldo).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </span>
              <div className="card-cartao-footer">
                <span className="small">
                  Atualizado em{' '}
                  {moment(cartao.atualizadoEm).format('DD/MM/YYYY HH:MM:SS')}
                </span>
              </div>
            </div>
          )}
          {!cartao && (
            <div className="card-sem-cartao">
              <span>
                Você ainda não possui seu Cartão MASTERCARD® Experiências SW
              </span>
              <div className="card-sem-cartao-footer"></div>
            </div>
          )}
        </Col>
        <Col xs={12} lg={4} className="mt-3 mb-3">
          <img className="w-100" src="/imagens/credito.png" alt="Crédito"></img>
        </Col>
        <Col xs={12} lg={4} className="mt-3 mb-3">
          <a href="https://surpreenda.naotempreco.com.br/rewards/vouchers/surpreenda">
            <img
              className="w-100"
              src="/imagens/mastercard.png"
              alt="Mastercard"
            ></img>
          </a>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={3} className="d-flex flex-column mt-3">
          {cartao &&
          cartao.numero &&
          cartao.bloqueado &&
          !cartao.emDesbloqueio ? (
            <>
              <button
                className="btn-limpo"
                onClick={(e) => setMostrarModalDesbloquearCartao(true)}
              >
                <div className="cartao-info-verde">
                  <i className="fa fa-unlock" />
                  <span>DESBLOQUEAR CARTÃO</span>
                </div>
              </button>
              <span className="mt-2 mt-1 font-weight-bold">
                SEU CARTÃO ESTÁ BLOQUEADO
              </span>
              <span className="text-justify mb-2">
                Ligue para <a href="tel:1533596265">(15) 3359-6265 </a> de
                segunda a sexta das 9h às 18h ou clique acima e solicite seu
                desbloqueio.
              </span>
            </>
          ) : null}
          {cartao &&
          cartao.numero &&
          cartao.bloqueado &&
          cartao.emDesbloqueio ? (
            <>
              <button className="btn-limpo">
                <div className="cartao-info-verde">
                  <i className="fa fa-unlock" />
                  <span>DESBLOQUEAR CARTÃO</span>
                </div>
              </button>
              <span className="mt-2 mt-1 font-weight-bold">
                SOLICITAÇÃO EM ANDAMENTO
              </span>
              <span className="text-justify mb-2">
                Ligue para <a href="tel:1151966448"> (15) 3359-6265 </a> de
                segunda a sexta das 9h às 18h ou clique acima e solicite seu
                desbloqueio.
              </span>
            </>
          ) : null}
          {!cartao && (
            <>
              <div className="cartao-info-verde">
                <i className="fa fa-unlock" />
                <span>DESBLOQUEAR CARTÃO</span>
              </div>
              <span className="mt-2 mt-1 font-weight-bold">
                VOCÊ NÃO POSSUI UM CARTÃO
              </span>
              <span className="text-justify mb-2">
                Ligue para <a href="tel:1151966448"> (15) 3359-6265</a> de
                segunda a sexta das 9h às 18h para mais dúvidas.
              </span>
            </>
          )}
          {cartao && cartao.numero && !cartao.bloqueado && (
            <>
              <div className="cartao-info-verde">
                <i className="fa fa-unlock" />
                <span>DESBLOQUEAR CARTÃO</span>
              </div>
              <span className="mt-2 mt-1 font-weight-bold">
                CARTÃO DESBLOQUEADO
              </span>
              <span className="text-justify mb-2">
                Ligue para <a href="tel:1151966448"> (15) 3359-6265 </a> de
                segunda a sexta das 9h às 18h para mais dúvidas.
              </span>
            </>
          )}
        </Col>
        <Col xs={12} md={6} lg={3} className="d-flex flex-column mt-3">
          {!cartao || (cartao && cartao.numero && cartao.bloqueado) ? (
            <>
              <div className="cartao-info-vermelho">
                <i className="fa fa-lock" />
                <span>BLOQUEAR CARTÃO</span>
              </div>
              <span className="mt-2 mt-1 font-weight-bold">PERDA OU ROUBO</span>
              <span className="text-justify mb-2">
                Você ainda não possui um Cartão MASTERCARD® Experiências SW para
                bloquear.
              </span>
            </>
          ) : null}
          {cartao && cartao.numero && cartao.bloqueado === 0 ? (
            <>
              <button
                className="btn-limpo"
                onClick={(e) => setMostrarModalBloquearCartao(true)}
              >
                <div className="cartao-info-vermelho">
                  <i className="fa fa-unlock" />
                  <span>BLOQUEAR CARTÃO</span>
                </div>
              </button>
              <span className="mt-2 mt-1 font-weight-bold">PERDA OU ROUBO</span>
              <span className="text-justify mb-2">
                Faça o bloqueio do seu cartão para inutilizar o uso do mesmo.
              </span>
            </>
          ) : null}
        </Col>
        <Col xs={12} md={6} lg={3} className="d-flex flex-column mt-3">
          <div className="cartao-info-preto">
            <i className="fa fa-phone" />
            <span>LIGAR PARA CENTRAL</span>
          </div>
          <span className="mt-2 mt-1 font-weight-bold">ATENDIMENTO</span>
          <span className="text-justify mb-2">
            Em caso de dúvidas, entre em contato pelo telefone
            <a href="tel:1151966448"> (15) 3359-6265 </a>
            de segunda a sexta das 9h às 18h.
          </span>
        </Col>
        <Col xs={12} md={6} lg={3} className="d-flex flex-column mt-3">
          <Link to="/loja/credito" style={{ textDecoration: 'none' }}>
            <div className="cartao-info-marrom">
              <i className="fa fa-credit-card" />
              <span>RECARREGUE SEU CARTÃO</span>
            </div>
          </Link>

          <span className="mt-2 mt-1 font-weight-bold">RECARREGUE</span>
          <span className="text-justify mb-2">
            Já tem o Cartão MASTERCARD® Experiências SW? Clique acima e RESGATE
            mais créditos para seu cartão.
          </span>
        </Col>
      </Row>
      <div className="mt-5">
        <div>
          <hr className="hr-loja mt-2" />
          <h5>REGULAMENTO CARTÃO MASTERCARD® Experiências SW</h5>
          <hr className="hr-loja mt-3" />
        </div>
        <ol>
          <li>
            <span>UTILIZAÇÃO DO CARTÃO</span>
            <ol>
              <li>
                <span>
                  No Primeiro resgate em dinheiro, você receberá seu cartão
                  Experiências SW. Os próximos resgates em dinheiro, serão
                  creditados automaticamente neste mesmo cartão. Cada Vendedor
                  Loja terá apenas 1 (um) cartão Experiências SW, é valido
                  somente 1 por CPF.
                </span>
              </li>
              <li>
                <span>
                  Utilize sempre seu CARTÃO MASTERCARD® Experiências SW na
                  função CRÉDITO.
                </span>
              </li>
              <li>
                <span>
                  Com o cartão em mãos, você pode fazer o desbloqueio
                  em experiencias-sw.com.br/cartao ou pelo telefone (11)
                  5196-6448 de segunda a sexta das 9h às 18h.
                </span>
              </li>
              <li>
                <span>
                  Para DESBLOQUEIO pelo site, o usuário precisa entrar no
                  sistema com seu CPF e senha.
                </span>
              </li>
              <li>
                <span>
                  O prazo da operadora para DESBLOQUEIO do seu cartão é de 24h
                  úteis após a solicitação.
                </span>
              </li>
              <li>
                <span>
                  Em caso de perda ou roubo do cartão, solicite o BLOQUEIO
                  em experiencias-sw.com.br/cartao ou pelo telefone (11)
                  5196-6448 de segunda a sexta das 9h às 18h.
                </span>
              </li>
              <li>
                <span>
                  O prazo da operadora para BLOQUEIO do seu cartão é de 24h
                  úteis após a solicitação.
                </span>
              </li>
              <li>
                <span>
                  Para alterar a senha, o usuário será direcionado para o site
                  da operadora do cartão onde por segurança, será necessário
                  completar os dados pessoais e fazer um cadastro ao painel de
                  controle.
                </span>
              </li>
              <li>
                <span>
                  Sua senha é pessoal e intransferível. Você pode alterar a
                  senha impressa na carta que acompanha seu cartão a qualquer
                  momento, clique aqui.
                </span>
              </li>
              <li>
                <span>
                  O Programa Experiências SW, não cobra taxas ou pontos para as
                  operações feitas dentro do painel do usuário no site da
                  operadora. As taxas são cobradas pela operadora do cartão e
                  constam neste regulamento.
                </span>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <span>SERVIÇOS E TAXAS DA OPERADORA DO CARTÃO </span>
            <ol>
              <li>
                <span>
                  O portador do cartão pode utilizar os seguintes serviços no
                  painel da operadora:
                  <br />
                  TRANSFERÊNCIA BANCÁRIA.
                  <br />
                  RECARGA DE CELULAR.
                  <br />
                  PAGAMENTO DE CONTA.
                  <br />
                  CONSULTA DE SALDO E EXTRADO DO CARTÃO.
                  <br />
                  ALTERAÇÃO DE SENHA.
                </span>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <span>TRANSFERÊNCIA BANCÁRIA</span>
            <ol>
              <li>
                <span>O Usuário deverá cadastrar uma conta em seu nome.</span>
              </li>
              <li>
                <span>
                  Não é permitido transferência para conta de terceiros.
                </span>
              </li>
              <li>
                <span>
                  Somente após a confirmação do código SMS e validação do saldo
                  do seu cartão é que a transferência será agendada.
                </span>
              </li>
              <li>
                <span>
                  Solicitações efetuadas ATÉ as 15:00 horas são enviadas ao
                  banco no mesmo dia.
                </span>
              </li>
              <li>
                <span>
                  Solicitações efetuadas APÓS as 15:00 horas são enviadas ao
                  banco no próximo dia útil. TED - Será postado na conta
                  corrente no mesmo dia. DOC para conta poupança - Será postado
                  na conta no dia seguinte.
                </span>
              </li>
              <li>
                <span>Tarifa para Transferência: R$ 5,50.</span>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <span>RECARGA DE CELULAR</span>
            <ol>
              <li>
                <span>
                  O premiado pode cadastrar o telefone dele ou de outras pessoas
                  para uso em suas recargas. Mas somente telefones cadastrados e
                  validados por um código SMS poderão ser recarregados.
                </span>
              </li>
              <li>
                <span>
                  Somente após a confirmação do código SMS e validação do saldo
                  do seu cartão é que a recarregar será feita em no telefone
                  pré-cadastrado.
                </span>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <span>PAGAMENTO DE CONTA</span>
            <ol>
              <li>
                <span>
                  Somente após a confirmação do código SMS e validação do saldo
                  do seu cartão é que o pagamento será feito. Um comprovante
                  será gerado validando a sua transação.
                </span>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <span>
              CONSULTA DE SALDO E EXTRATO DO CARTÃO E ALTERAÇÃO DE SENHA
            </span>
            <ol>
              <li>
                <span>
                  No painel online da operadora de cartão, não haverá custo.
                </span>
              </li>
              <li>
                <span>
                  Para consulta de saldo online ou alteração de senha, o
                  premiado deverá estar com o cadastro completo no painel da
                  operadora do cartão.
                </span>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <span>BANCO 24h</span>
            <ol>
              <li>
                <span>
                  O Premiado pode consultar o saldo nos bancos 24h mediante taxa
                  de R$ 2,24.
                </span>
              </li>
              <li>
                <span>
                  O Premiado pode efetuar saques na rede Banco 24h mediante
                  tarifa de R$ 9,00.
                </span>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <span>TAXA DE INATIVIDADE</span>
            <ol>
              <li>
                <span>
                  O Experiências SW, deverá enviar uma foto da Nota Fiscal /
                  Cupom Fiscal pelo site experiencias-sw.com.br para que a
                  pontuação seja realizada e confirmada.
                </span>
              </li>
              <li>
                <span>
                  Em caso do cartão estiver sem saldo, não será cobrado a taxa
                  de inatividade.
                </span>
              </li>
              <li>
                <span>
                  Em caso de perda do cartão, você poderá receber um novo cartão
                  sem custo, mas para transferir o saldo para um novo cartão,
                  será cobrado pela operadora do cartão uma taxa de 2% do seu
                  saldo atual.
                </span>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <span>MASTERCARD® SURPREENDA</span>
            <ol>
              <li>
                <span>
                  O Usuário pode se cadastrar no programa MASTERCARD®
                  SURPREENDA, que é um programa de vantagens administrado pela
                  MASTERCARD®.
                </span>
              </li>
              <li>
                <span>
                  Todo o acesso e controle do programa MASTERCARD® SURPREENDA é
                  separado e independente do Experiências SW.
                </span>
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <ModalDesbloquearCartao
        show={mostrarModalDesbloquearCartao}
        onHide={fecharModalDesbloquearCartao}
      />
      <ModalBloquearCartao
        show={mostrarModalBloquearCartao}
        onHide={fecharModalBloquearCartao}
      />
    </Container>
  );
}
