/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ModalMidia from '../ModalMidia';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';

export default function PontuacoesEncerradas() {
  const [data, setData] = useState([]);
  const [mostrarModalFoto, setMostrarModalFoto] = useState(false);
  const [linkArquivo, setLinkArquivo] = useState(null);
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [tipo, setTipo] = useState('');
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState('criadoEm');
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  useEffect(() => {
    buscarRedes();
  }, []);

  const columns = [
    {
      text: 'Vendedor Loja',
      dataField: 'parceiro',
      sort: true,
    },
    {
      text: 'CPF',
      dataField: 'cpf',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
    {
      text: 'Código Loja',
      dataField: 'codRevenda',
      sort: true,
    },
    {
      text: 'Loja',
      dataField: 'revenda',
      sort: true,
    },
    {
      text: 'Data do Envio',
      dataField: 'criadoEm',
      formatter: (celula, valor) => {
        return moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Data do Cancelamento',
      dataField: 'atualizadoEm',
      formatter: (celula, valor) => {
        return moment(valor.atualizadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Foto da Venda',
      dataField: 'linkArquivo',
      formatter: (celula, valor) =>
        valor.linkArquivo ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalMidia(valor.linkArquivo, valor.tipoArquivo);
            }}
          >
            <i className="fas fa-image"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            SEM FOTO
          </button>
        ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'criadoEm',
      order: 'desc',
    },
  ];

  const buscarPontuacoes = async (pageProps = {}) => {
    if (buscando) return;
    
    setBuscando(true);
    const params = {
      rede: redeSelecionada,
      page: pageProps.page || page,
      perPage: pageProps.sizePerPage || sizePerPage,
      sortField: pageProps.sortField || sortField,
      sortOrder: pageProps.sortOrder || sortOrder,
      search: pageProps.search || searchTerm
    };

    try {
      const resultado = await api.get('/api/pontuacoes/encerradas', { params });
      
      if (resultado?.data) {
        setData(resultado.data.pontuacao);
        setTotalSize(resultado.data.totalRecords);
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      setBuscando(false);
    }
  };

  const visualizarModalMidia = (linkArquivo, tipo) => {
    if (!linkArquivo) return;

    if (tipo !== 'image') {
      window.open(linkArquivo, '_blank');
    } else {
      setLinkArquivo(linkArquivo);
      setTipo(tipo);
      setMostrarModalFoto(true);
    }
  };

  const fecharModalFoto = () => {
    setMostrarModalFoto(false);
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const handleTableChange = (type, newState) => {
    if (newState.sortField && newState.sortField !== sortField) {
      setSortField(newState.sortField);
    }
    if (newState.sortOrder && newState.sortOrder !== sortOrder) {
      setSortOrder(newState.sortOrder);
    }
    if (newState.page !== page) {
      setPage(newState.page);
    }
    if (newState.sizePerPage !== sizePerPage) {
      setSizePerPage(newState.sizePerPage);
    }

    buscarPontuacoes(newState);
  };

  useEffect(() => {
    if (redeSelecionada !== undefined) {
      setPage(1);
      buscarPontuacoes({ page: 1 });
    }
  }, [redeSelecionada]);

  const options = {
    page,
    sizePerPage,
    totalSize,
    showTotal: true,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const handleSearch = () => {
    setPage(1);
    buscarPontuacoes({ 
      page: 1,
      sizePerPage,
      sortField,
      sortOrder,
      search: searchTerm 
    });
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações Encerradas</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button
            className="btn-busca"
            type="submit"
            onClick={() => {
              setPage(1);
              buscarPontuacoes({ page: 1 });
            }}
          >
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse align-items-center mb-3">
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    className="input-theme me-2"
                    style={{ minWidth: '200px' }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Buscar"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch();
                      }
                    }}
                  />
                  <button
                    className="btn-busca"
                    type="button"
                    onClick={handleSearch}
                    title="Pesquisar"
                    style={{ marginLeft: '8px' }}
                  >
                    {buscando ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      <i className="fas fa-search"></i>
                    )}
                  </button>
                </div>
              </div>
              <BootstrapTable
                remote={{ pagination: true, sort: true }}
                onTableChange={handleTableChange}
                loading={buscando}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalMidia
        show={mostrarModalFoto}
        onHide={fecharModalFoto}
        linkArquivo={linkArquivo}
        tipo={tipo}
      />
    </Container>
  );
}
