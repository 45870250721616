import React, { useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { useState } from 'react';
import api from '../../../services/API';
import { toast } from 'react-toastify';

export default function SelectCity(props) {
  const [cities, setCities] = useState([]);

  const fetchCities = async () => {
    const queryParams = new URLSearchParams();
    queryParams.append('categories', props.categories);
    queryParams.append('experience', props.experience);

    const response = await api.get(`/api/tlc-cities?${queryParams.toString()}`);

    if (response?.status !== 200) {
      return toast.error('Erro ao carregar cidades', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    const data = response.data.map((city, index) => ({
      label: city.localizedName,
      value: city.name,
      id: index + 1,
    }));
    setCities(data);

    return data;
  };

  async function filterCities(search) {
    const filtered = await cities.filter((city) => {
      return search
        ? city.label.toLowerCase().includes(search?.toLowerCase())
        : cities;
    });
    return filtered;
  }

  return (
    <AsyncSelect
      noOptionsMessage={() => 'Sem resultados'}
      onChange={props.onChange}
      placeholder="Buscar cidade..."
      className="react-select-container mb-3"
      classNamePrefix="react-select"
      cacheOptions
      defaultOptions
      loadOptions={cities.length === 0 ? fetchCities : filterCities}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          text: 'black',
          primary25: '#005a99',
          primary: '#005a99',
        },
      })}
    />
  );
}
