import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import Slider from '../../../components/Loja/Slider';

export default function Home() {
  useEffect(() => {
    document.title = 'Experiências SW';
  }, []);

  return (
    <>
      <Header auto />
      <div className="mt-5">
        <Slider />
      </div>
      <Footer />
    </>
  );
}
