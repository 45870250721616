export function buildAddress(location) {
  let address = '';

  if (!location) return address;
  if (location.address1) address = address.concat(location.address1);
  if (location.address2) address = address.concat(` - ${location.address2}`);
  if (location.city) address = address.concat(`, ${location.city}`);
  if (location.region) address = address.concat(`, ${location.region}`);
  if (location.postCode) address = address.concat(`, ${location.postCode}`);

  return address;
}
