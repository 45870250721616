/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import './styles.css';
import { atualizarSaldo } from '../../../store/modules/parceiro/actions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment-timezone';

const columns = [
  {
    text: 'Nome',
    dataField: 'Nome',
    formatter: (celula, valor) => {
      if (valor) {
        return valor.nome;
      }
    },
    sort: true,
  },
  {
    text: 'CPF',
    dataField: 'CPF',
    formatter: (celula, valor) => {
      return valor.cpf;
    },
    sort: false,
  },
  {
    text: 'Celular',
    dataField: 'Celular',
    formatter: (celula, valor) => {
      return valor.celular;
    },
    sort: false,
  },
  {
    text: 'Indicação Válida',
    dataField: 'Indicação Válida',
    formatter: (celula, valor) => {
      return (valor.validado && 'Sim') || 'Não';
    },
    sort: true,
  },
];

export default function Indicacao() {
  const [buscando, setBuscando] = useState(false);
  const [data, setData] = useState([]);

  // const getIndicacoes = async () => {
  // if (parceiro.idIndicacao) {
  // setBuscando(true);
  //
  // const resultado = await api.post(`/api/indique-ganhe/indicacoes`, {
  // idIndicacao: parceiro.idIndicacao,
  // });
  //
  // console.log(resultado && resultado.data);
  //
  // setBuscando(false);
  // if (resultado && resultado.data) {
  // setData(resultado.data);
  // let validados = 0;
  // for (let index = 0; index < resultado.data.length; index++) {
  // if (resultado.data[index].validado) {
  // validados += 1;
  // }
  // setIndicacoesValidas(validados);
  // }
  // }
  // return;
  // }
  // };

  //dispatch(atualizarSaldo(Number(resultado.data.score)));

  // useEffect(() => {
  // getIndicacoes();
  // }, []);
  const [indicacoesValidas, setIndicacoesValidas] = useState(0);

  //const [pontosAdquiridos, setPontosAdquiridos] = useState(0);
  //const [CPFIndicacao, setCodigoPromocional] = useState('');
  //const [celularIndicacao, setCodigoPromocional] = useState('');

  //const [cpf] = useState(parceiro.cpf);
  const parceiro = useSelector((state) => state.parceiro);

  const getIndicacoes = async () => {
    if (parceiro.idIndicacao) {
      setBuscando(true);

      try {
        const resultado = await api.get(`/api/indicacoes/${parceiro.idIndicacao}`);

        console.log(resultado.data);

        if (resultado?.data) {
          setData(resultado.data);
          let validados = 0;
          for (let index = 0; index < resultado.data.length; index++) {
            if (resultado.data[index].validado) {
              validados += 1;
            }
          }
          setIndicacoesValidas(validados);
        }
      } catch (error) {
        console.error('Erro ao buscar indicações:', error);
      }
      setBuscando(false);
    }
  };

  useEffect(() => {
    getIndicacoes();
  }, []);

  const calcularPontosTotais = (indicacoes) => {
    return indicacoes.reduce((total, indicacao) => {
      if (!indicacao.validado) return total;
      
      switch (indicacao.plataforma) {
        case 'Indicado por Cadastro':
          return total + 500;
        case 'SW PRO Cursos':
          return total + 750;
        case 'Ache Seu Pintor':
          return total + 1500;
        default:
          return total;
      }
    }, 0);
  };

  return (
    <Container className="mt-5 mb-5">
      <div className="mt-5">
        <h5>INDIQUE E GANHE</h5>
        <hr className="hr-loja mt-0" />
      </div>

      <h5 className="ml-4">
        Seu código de indicação é <b>{parceiro.idIndicacao}</b>. Informe este
        código aos pintores que deseja indicar. A indicação será efetivada após
        a validação da participação feita com o envio da primeira nota ou cupom
        fiscal do pintor indicado. O cadastros nas demais plataformas do clube
        pro pintor, bem como a efetivação de cursos na plataforma também geram
        pontos.
      </h5>

      <div className="mt-5">
        <h5>MEUS INDICADOS</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <BootstrapTable
          bootstrap4
          noDataIndication={() =>
            buscando ? (
              <span>
                Carregando
                <i className="fas fa-circle-notch fa-spin ml-3"></i>
              </span>
            ) : (
              <span>Sem resultados</span>
            )
          }
          className="tabela-fundo-claro"
          keyField="id"
          data={data}
          columns={columns}
          pagination={paginationFactory()}
          headerClasses="tabela-header-claro"
          bodyClasses="tabela-body-claro"
        />
        <h5>
          Você tem <b>{indicacoesValidas} indicações válidas</b> e acumulou{' '}
          <b>{calcularPontosTotais(data)} pontos</b> com suas indicações.
        </h5>
      </div>
    </Container>
  );
}
