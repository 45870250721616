import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import ModalEditar from './ModalEditar/index';

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'id',
    order: 'desc',
  },
];

export default function OfertasTLC() {
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const buscarOfertas = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/ofertas');
    if (resultado) {
      setData(resultado.data);
    }
    setBuscando(false);
  };

  const columns = [
    {
      dataField: 'id',
      text: 'Id',
    },
    {
      dataField: 'offerId',
      text: 'Id da Oferta',
    },
    {
      dataField: 'pontos',
      text: 'Pontos',
    },
    {
      dataField: 'editar',
      text: 'Editar',
      isDummyField: true,
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            setSelectedData(valor);
            setShowModal(true);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
  ];

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  useEffect(() => {
    buscarOfertas();
  }, []);

  const atualizarOferta = (dataUpdated) => {
    setData(
      data.map((d) => {
        if (d.id === dataUpdated.id) {
          d = { ...d, ...dataUpdated };
        }
        return d;
      })
    );
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Ofertas TLC</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                keyField="codigo"
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalEditar
        show={showModal}
        onHide={() => {
          setSelectedData({});
          setShowModal(false);
        }}
        data={selectedData}
        onChange={setSelectedData}
        atualizarOferta={atualizarOferta}
      />
    </Container>
  );
}
