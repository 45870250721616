import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [data, setData] = useState({});
  const [codigoPadrao, setCodigoPadrao] = useState('');
  const [nomePadrao, setNomePadrao] = useState('');
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');
  const [valorUnitario, setValorUnitario] = useState('');
  const [cnpjRevenda, setCnpjRevenda] = useState('');

  const atualizar = async (e) => {
    e.preventDefault();

    if (!codigoPadrao) {
      toast.error('Código Experiências SW obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!codigo) {
      toast.error('Código Loja obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!nome) {
      toast.error('Descrição Loja obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!valorUnitario) {
      toast.error('Preço Unitário Loja obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEditando(true);
    const resultado = await api.put(`/api/produtos_revenda/${data.id}`, {
      codigoPadrao,
      codigo,
      nome,
      valorUnitario,
      cnpjRevenda,
    });

    if (resultado) {
      toast.success(`Produto alterado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
    }
    setEditando(false);
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setCodigoPadrao(props.data.codigoPadrao);
      setCodigo(props.data.codigo);
      setNome(props.data.nome);
      setValorUnitario(props.data.valorUnitario);
      setCnpjRevenda(props.data.cnpjRevenda);
      buscarCodigoPadrao({ target: { value: props.data.codigoPadrao } });
    }
  }, [props]);

  const buscarCodigoPadrao = async (element) => {
    let codigo = element.target.value;
    if (!codigo) return;
    const resultado = await api.get(`/api/produtos?codigo=${codigo}`);
    if (resultado.data.produtos.length === 0) {
      toast.error('Produto Experiências SW não encontrado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCodigoPadrao('');
      setNomePadrao('');
    } else {
      setNomePadrao(resultado.data.produtos[0].nome);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div className="d-flex flex-column align-items-center">
          <form className="form" onSubmit={atualizar}>
            <label htmlFor="codigoPadrao">Código Experiências SW</label>
            <input
              id="codigoPadrao"
              name="codigoPadrao"
              type="text"
              placeholder="Código Experiências SW"
              onChange={(e) => setCodigoPadrao(e.target.value)}
              value={codigoPadrao}
              onBlur={buscarCodigoPadrao}
            ></input>
            <label htmlFor="revenda">Descrição Experiências SW</label>
            <input
              id="nomePadrao"
              name="nomePadrao"
              type="text"
              placeholder="Descrição Experiências SW"
              disabled
              value={nomePadrao}
            ></input>
            <label htmlFor="codigo">Código Loja</label>
            <input
              id="codigo"
              name="codigo"
              type="text"
              placeholder="Código Loja"
              onChange={(e) => setCodigo(e.target.value)}
              value={codigo}
            ></input>
            <label htmlFor="nome">Descrição Loja</label>
            <input
              id="nome"
              name="nome"
              type="text"
              placeholder="Descrição Loja"
              onChange={(e) => setNome(e.target.value)}
              value={nome}
            ></input>
            <label htmlFor="valorUnitario">Preço Unitário Loja</label>
            <input
              id="valorUnitario"
              name="valorUnitario"
              type="number"
              step="0.01"
              placeholder="Preço Unitário Loja"
              onChange={(e) => setValorUnitario(e.target.value)}
              value={valorUnitario}
            ></input>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {editando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
