/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import './styles.css';
import { LoaderContext } from '../../Loader';
import { toast } from 'react-toastify';
import { buildAddress } from '../../../utils';
import SelectCity from '../SelectCity';

export default function PremiosLoja() {
  const [busca, setBusca] = useState('');
  const [premios, setPremios] = useState([]);
  const { setIsLoading, isLoading } = useContext(LoaderContext);

  const [category, setCategory] = useState();
  const [experience, setExperience] = useState();

  const url = new URLSearchParams(window.location.search);
  const page = url.get('page');
  const categoriaURL = url.get('categoria');
  const experienceURL = url.get('experience');
  const urlCities = url.getAll('cities');

  const [loadingCategory, setLoadingCategory] = useState(true);
  const [loadingExperiences, setLoadingExperiences] = useState(true);
  const [loadingPremios, setLoadingPremios] = useState(true);

  useEffect(() => {
    if (!loadingCategory && !loadingExperiences && !loadingPremios) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [loadingCategory, loadingExperiences, loadingPremios]);

  useEffect(() => {
    if (!categoriaURL || !experienceURL) historico.push('/home');
    document.title = 'Experiências SW - Loja';

    loadCategory();
    loadExperiences();
    buscarPremios();
  }, []);

  const loadCategory = async () => {
    try {
      const { data } = await api.get('/api/tlc-categories');

      if (data) {
        const aux = data.categorias.find(
          (item) => item.id === Number(categoriaURL)
        );
        if (aux) setCategory(aux);
        else
          toast.error('Categoria não encontrada.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          });
      }
    } catch (erro) {
      toast.error('Erro interno.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setLoadingCategory(false);
    }
  };

  const loadExperiences = async () => {
    try {
      const { data } = await api.get(
        `/api/tlc-experiences?categories=${categoriaURL}`
      );
      if (data) {
        const find = data.find((item) => item.id === Number(experienceURL));
        setExperience(find);
      } else {
        toast.error('Erro interno.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      }
    } catch (erro) {
      console.error('erro: ', erro);
      toast.error('Erro interno.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setLoadingExperiences(false);
    }
  };

  const buscarPremios = async () => {
    const queryParams = new URLSearchParams();
    queryParams.append('categories', categoriaURL);
    queryParams.append('experience', experienceURL);
    if (page) queryParams.append('page', page);
    if (urlCities && urlCities.length > 0)
      queryParams.append('cities', urlCities.join(','));
    try {
      const resultado = await api.get(
        `/api/tlc-offers?${queryParams.toString()}`
      );
      if (resultado) {
        setPremios(resultado.data.premios.filter((p) => Number(p.pontos) > 0));
      }
    } finally {
      setLoadingPremios(false);
    }
  };

  function limparFiltros() {
    const currentUrl = new URLSearchParams(window.location.search);
    currentUrl.delete('cities');
    historico.push(`/loja?${currentUrl.toString()}`);
  }

  function handleEnter(e) {
    if (e.key === 'Enter') {
      buscaComTexto();
    }
  }

  function buscaComTexto() {
    buscarPremios();
  }

  const handleCitySelect = async (id) => {
    const queryParams = new URLSearchParams(window.location.search);
    const urlCities = queryParams.getAll('cities');
    if (urlCities) {
      if (urlCities.includes(String(id))) {
        queryParams.delete('cities', id);
      } else {
        queryParams.append('cities', id);
      }
    }
    url.set('cities', queryParams.toString());
    historico.push(`/loja?${queryParams.toString()}`);
  };

  return (
    <Container className="mb-5">
      <Breadcrumb>
        <a href="/home">CATEGORIA: {category?.nome}</a>
        <div style={{ padding: '0 16px' }}>{' > '}</div>
        <a href={`experiencias?categoria=${categoriaURL}`}>
          EXPERIÊNCIA: {experience?.title}
        </a>
      </Breadcrumb>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <Row className="sticky">
            <Col xs={12}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>CIDADES</h5>
                <div className="limpar-filtros">
                  <i className="fas fa-trash mr-3"></i>
                  <button className="btn-limpo" onClick={limparFiltros}>
                    Limpar filtros
                  </button>
                </div>
              </div>
              <hr className="hr-style" />
            </Col>
            <Col>
              <SelectCity
                categories={categoriaURL}
                experience={experienceURL}
                onChange={(data) => {
                  handleCitySelect(data.value);
                }}
              />
            </Col>
          </Row>
          {urlCities.length > 0 && (
            <Row style={{ marginBottom: 16 }}>
              <Col>
                <p>Filtros: </p>
                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
                >
                  {urlCities.map((city, index) => (
                    <div
                      key={index}
                      className="filter-city-item"
                      onClick={() => {
                        handleCitySelect(city);
                      }}
                    >
                      <p>{city}</p>
                      <i class="fas fa-trash"></i>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          )}
        </Col>

        <Col xs={12} md={8} lg={9}>
          <div>
            <div className="d-flex flex-row justify-content-between align-items-center mb-0 pb-0">
              <h5>LOJA</h5>
              <div>
                <input
                  value={busca}
                  onChange={(e) => setBusca(e.target.value)}
                  onKeyUp={handleEnter}
                  className="input-busca"
                  placeholder="O que deseja resgatar?"
                />
                <button
                  className="btn-busca mt-0"
                  type="submit"
                  onClick={buscaComTexto}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <hr className="hr-loja" />
          </div>

          <Row className="awards-container">
            {premios.length > 0 ? (
              premios.map((premio) => (
                <div
                  key={premio.id}
                  style={{
                    padding: 16,
                    borderBottom: '1px solid #ccc',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <span>
                        <strong>{premio.nome}</strong>
                      </span>
                      <br />
                      <span>
                        <strong>{premio.partner.name}</strong>
                      </span>
                    </div>
                    <img
                      src={premio.location?.logoUrl}
                      alt={premio.partner.name}
                      style={{
                        width: 'auto',
                        height: 100,
                        maxWidth: 250,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: 16,
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 16,
                    }}
                  >
                    {/* premio.location.region && premio.location.city */}
                    <div>
                      <p>
                        <span>{premio.partner.name}</span>
                        {buildAddress(premio.location) &&
                          `, localizado no Endereço ${buildAddress(
                            premio.location
                          )}`}
                      </p>
                      <br />
                      {/* <span>{premio.offerTerms}</span> */}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ fontWeight: 'bold' }}>
                        {premio.pontos} pontos
                      </div>
                      <button
                        style={{ padding: '8px 16px' }}
                        className="btn-primario"
                        onClick={() => historico.push(`/premio/${premio.id}`)}
                      >
                        RESGATAR
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  width: '100%',
                  display: 'grid',
                  placeContent: 'center',
                  padding: '64px',
                }}
              >
                <p>Nenhuma oferta encontrada...</p>
              </div>
            )}
            {/* {premios.map((premio) => (
              <Col
                key={premio.codigo}
                id={premio.codigo}
                name="premio"
                xs={12}
                md={4}
                lg={3}
                className="mt-5"
              >
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'space-between',
                      alignItems: 'center',
                      minHeight: '100px',
                    }}
                  >
                    <Link to={`/premio/${premio.codigo}`}>
                      <img
                        alt={premio.nome}
                        className="w-100"
                        src={premio.diretorioFoto}
                      ></img>
                    </Link>
                  </div>
                  <span className="card-premio-nome">{premio.nome}</span>
                  <Link to={`/premio/${premio.codigo}`}>
                    <button className="btn-resgatar mt-1">RESGATAR</button>
                  </Link>
                </div>
              </Col>
            ))} */}
          </Row>
          {premios.length >= 20 && (
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: 32,
                alignItems: 'center',
              }}
            >
              {page && page > 1 ? (
                <div
                  onClick={() => {
                    if (page && page > 1) url.set('page', Number(page) - 1);
                    historico.push(`/loja?${url.toString()}`);
                  }}
                  className="btn-primario"
                  style={{
                    width: 40,
                    height: 40,
                    display: 'grid',
                    placeContent: 'center',
                    marginTop: 0,
                  }}
                >
                  {`<`}
                </div>
              ) : (
                <div></div>
              )}
              <div> {page ? page : 1} </div>
              {premios.length > 0 ? (
                <div
                  onClick={() => {
                    if (page) url.set('page', Number(page) + 1);
                    else url.set('page', 2);
                    historico.push(`/loja?${url.toString()}`);
                  }}
                  className="btn-primario"
                  style={{
                    width: 40,
                    height: 40,
                    display: 'grid',
                    placeContent: 'center',
                    marginTop: 0,
                  }}
                >
                  {`>`}
                </div>
              ) : (
                <div></div>
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}
