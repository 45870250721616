import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import { useParams } from 'react-router-dom';
import './styles.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ModalItensPontuacao from '../ModalItensPontuacao';
import ModalMidia from '../ModalMidia';
import moment from 'moment-timezone';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const { SearchBar } = Search;

export default function DetalheParceiro() {
  const { cpf } = useParams();
  const [parceiro, setParceiro] = useState([]);
  const [pontuacoes, setPontuacoes] = useState([]);
  const [mostrarModalItens, setMostrarModalItens] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [mostrarModalFoto, setMostrarModalFoto] = useState(false);
  const [linkArquivo, setLinkArquivo] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [buscando, setBuscando] = useState(false);
  useEffect(() => {
    setBuscando(true);
    buscarParceiro();
    buscarPontuacoes();
    setBuscando(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const buscarPontuacoes = async () => {
    const pontuacoes = await api.get(`/api/pontuacoes/${cpf}`);
    if (pontuacoes.data) {
      setPontuacoes(pontuacoes.data.pontuacoes);
    }
  };

  const buscarParceiro = async () => {
    const resultado = await api.get(`/api/parceiros?cpf=${cpf}`);
    if (resultado) {
      const parceiro = resultado.data.parceiros[0];

      let cpf = parceiro.cpf.replace(/[^\d]/g, '');
      parceiro.cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

      if (parceiro.dataNascimento) {
        parceiro.dataNascimento = moment(parceiro.dataNascimento).format(
          'DD/MM/YYYY'
        );
      } else {
        parceiro.dataNascimento = '';
      }

      parceiro.celular = `(${parceiro.celular.substring(
        0,
        2
      )}) ${parceiro.celular.substring(2, 7)}-${parceiro.celular.substring(7)}`;

      parceiro.aceitouRegulamento = parceiro.aceitouRegulamento ? 'Sim' : 'Não';

      parceiro.endCep = `${parceiro.endCep.substring(
        0,
        5
      )}-${parceiro.endCep.substring(5)}`;

      parceiro.endereco = parceiro.endComplemento
        ? `${parceiro.endRua}, ${parceiro.endNumero}<br/>${parceiro.endBairro} - ${parceiro.endComplemento}<br/>${parceiro.endCidade} - ${parceiro.endEstado}`
        : `${parceiro.endRua}, ${parceiro.endNumero}<br/>${parceiro.endBairro}<br/>${parceiro.endCidade} - ${parceiro.endEstado}`;

      setParceiro(parceiro);
    }
  };

  const visualizarModalItens = (produtos) => {
    setProdutos(produtos);
    setMostrarModalItens(true);
  };

  const fecharModalItens = () => {
    setMostrarModalItens(false);
  };

  const visualizarModalMidia = (linkArquivo, tipo) => {
    if (!linkArquivo) return;

    if (tipo !== 'image') {
      window.open(linkArquivo, '_blank');
    } else {
      setLinkArquivo(linkArquivo);
      setTipo(tipo);
      setMostrarModalFoto(true);
    }
  };

  const fecharModalFoto = () => {
    setMostrarModalFoto(false);
  };

  const columns = [
    {
      text: 'Venda',
      dataField: 'identificador',
    },
    {
      text: 'Pontos',
      dataField: 'totalPontos',
      formatter: (celula, valor) => {
        return Number(valor.totalPontos).toLocaleString('pt-BR');
      },
    },
    {
      text: 'Data da Venda',
      dataField: 'realizadoEm',
      formatter: (celula, valor) => {
        return moment(valor.realizadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
    },
    {
      text: 'Data da Pontuação',
      dataField: 'pontuadoEm',
      formatter: (celula, valor) => {
        return moment(valor.pontuadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Validade dos Pontos',
      dataField: 'venceEm',
      formatter: (celula, valor) => {
        return moment(valor.venceEm).format('DD/MM/YYYY HH:mm:ss');
      },
    },
    {
      text: 'Itens da Venda',
      dataField: 'itens',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => visualizarModalItens(valor.itens)}
          >
            <i className="fas fa-list"></i>
          </button>
        );
      },
    },
    {
      text: 'Foto da Venda',
      dataField: 'linkArquivo',
      formatter: (celula, valor) => {
        if (valor.linkArquivo) {
          return (
            <button
              className="btn-limpo f-12 p-1"
              onClick={() => {
                visualizarModalMidia(valor.linkArquivo, valor.tipoArquivo);
              }}
            >
              <i className="fas fa-image"></i>
            </button>
          );
        } else {
          return (
            <button className="btn-limpo f-12 p-1" disabled>
              SEM FOTO
            </button>
          );
        }
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'pontuadoEm',
      order: 'desc',
    },
  ];

  return (
    <Container fluid>
      {buscando ? (
        <h4 className="pt-3 pb-3 text-muted">Carregando...</h4>
      ) : (
        <h4 className="pt-3 pb-3 f-black">{parceiro.nome}</h4>
      )}
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>CPF</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{parceiro.cpf}</span>
            )}
            <br />
            <span>
              <b>Data de Nascimento</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{parceiro.dataNascimento}</span>
            )}
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Celular</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{parceiro.celular}</span>
            )}
            <br />
            <span>
              <b>Email </b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{parceiro.email}</span>
            )}
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Loja</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{parceiro.revenda}</span>
            )}
            <br />
            <span>
              <b>Aceitou Regulamento</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{parceiro.aceitouRegulamento}</span>
            )}
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Endereço</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: parceiro.endereco }}
              ></span>
            )}
            <br />
            <span>
              <b>CEP</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{parceiro.endCep}</span>
            )}
          </div>
        </Col>
      </Row>
      <h4 className="pt-3 pb-3 f-black">Vendas</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={pontuacoes}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory()}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalItensPontuacao
        show={mostrarModalItens}
        onHide={fecharModalItens}
        produtos={produtos}
      />
      <ModalMidia
        show={mostrarModalFoto}
        tipo={tipo}
        onHide={fecharModalFoto}
        linkArquivo={linkArquivo}
      />
    </Container>
  );
}
