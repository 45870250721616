import React from 'react';
import './styles.css';

export default function Layout({ children }) {
  return (
    <main className="content-wrapper">
      <header className="header">
        <img
          src="/imagens/logo-experiencias-sw-branco.png"
          alt="logo experiências sw"
        />
        <img src="/imagens/tlc-logo.svg" alt="logo experiências sw" />
      </header>
      <section className="vertical-section">
        <div className="icon-wrapper">
          <i class="far fa-calendar-alt"></i>
          <p className="description">
            Agende sua experiência com pelo menos 7 dias de antecedência
          </p>
        </div>
        <div className="icon-wrapper">
          <i class="fas fa-file"></i>
          <p className="description">
            Apresente este voucher e um documento oficial de identificação no
            estabelecimento
          </p>
        </div>
        <div className="icon-wrapper">
          <i class="far fa-star"></i>
          <p className="description">Aproveite a sua experiência!</p>
        </div>
      </section>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          margin: '0 2rem',
        }}
      >
        {children}
      </section>
      <footer
        style={{
          fontSize: 14,
          backgroundColor: '#eee',
          padding: '1.5rem 2rem ',
          textAlign: 'justify',
          color: '#333',
        }}
      >
        Este voucher é pessoal, intransferível e pode ser usado somente uma vez
        pelo titular maior de 18 anos deste voucher. O uso deste voucher de
        parceiro e/ou código de parceiro implica na aceitação das condições
        gerais da campanha, assim como das condições dos parceiros. O prêmio
        deste voucher não pode ser modificado ou trocado por outra oferta. Nós
        não garantimos a troca superior a um voucher de parceiro e/ou código de
        parceiro na mesma empresa colaboradora. Confira o prazo de validade do
        seu voucher e código.
      </footer>
    </main>
  );
}

export function Section({ children, title }) {
  return (
    <section style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <p
          style={{
            fontSize: '1.25rem',
            fontWeight: '900',
            color: '#333',
            whiteSpace: 'nowrap',
            margin: 0,
          }}
        >
          {title}
        </p>
        <div
          style={{
            backgroundColor: '#1e375d',
            height: '1.25rem',
            width: '100%',
            borderRadius: '0.25rem',
          }}
        ></div>
      </div>
      {children}
    </section>
  );
}
