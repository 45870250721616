import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './styles.css';

export default function ModalVideoWrapper(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="video-modal"
      fullscreen={true}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ position: 'relative' }}>{props.children}</Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
