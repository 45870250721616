import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../services/API';

const ModalBanner = ({ handleClose, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const showedModal = sessionStorage.getItem('showedModal');

    carregarBanners();

    if (!showedModal) {
      //carregarBanners();
    } else {
      //handleClose();
    }
  }, []);

  const carregarBanners = async () => {
    try {
      const response = await api.get('/api/banners/intervalo');
      const { data } = response || {};

      if (data && data.length > 0) {
        setBanners(data);
        setShowModal(true);
      } else {
        handleClose();
      }
    } catch (error) {
      console.error('Erro ao carregar banners:', error);
    }

  };

  const handleHideBanner = () => {
    if (banners.length > 0) {
      const temp = Array.from(banners);
      temp.shift();
      setBanners(temp);
      if (temp.length === 0) {
        sessionStorage.setItem('showedModal', true);
        setShowModal(false);
        handleClose();
      }
    } else {
      setShowModal(false);
      handleClose();
    }
  };

  return (
    <Modal
      {...props}
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="banner-modal"
      onHide={handleHideBanner}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ width: '100%', textAlign: 'center' }}>
          {banners && banners.length > 0 && banners.at(0).titulo}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {banners && banners.length > 0 && banners.at(0).descricao ? (
            <div
              style={{ textAlign: 'center' }}
              dangerouslySetInnerHTML={{
                __html:
                  banners && banners.length > 0 && banners.at(0).descricao,
              }}
            ></div>
          ) : null}
          {banners && banners.length > 0 && banners.at(0).linkArquivo && (
            <div
              style={{
                maxHeight: '80vh',
                maxWidth: '80vw',
              }}
            >
              <img
                style={{
                  height: '100%',
                  width: '100%',
                  overflow: 'hidden',
                  objectFit: 'contain',
                }}
                src={banners.at(0).linkArquivo}
              />
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalBanner;
