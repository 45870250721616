import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Viewer from 'react-viewer';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import SelectProduct from '../SelectProduct';
import ModalReprovarNota from '../ModalReprovarNota';

function NovaVenda(props) {
  const [enviando, setEnviando] = useState(false);
  const [itens, setItens] = useState([]);
  const [cpf, setCpf] = useState('');
  const [parceiro, setParceiro] = useState('');
  const [codRevenda, setCodRevenda] = useState('');
  const [cnpjRevenda, setCnpjRevenda] = useState('');
  const [idRevenda, setIdRevenda] = useState('');
  const [tipoFiscal, setTipoFiscal] = useState('');
  const [identificador, setIdentificador] = useState('');
  const [realizadoEm, setRealizadoEm] = useState('');
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');
  const [valorUnitario, setValorUnitario] = useState('');
  const [qtde, setQtde] = useState('');
  const [valorDescontoItem, setValorDescontoItem] = useState('');
  const [subTotalItem, setSubTotalItem] = useState('');
  const [descontos, setDescontos] = useState('');
  const [total, setTotal] = useState('');
  const [url, setUrl] = useState('');
  const [colFormat, setColFormat] = useState(12);
  const [enviarMensagem, setEnviarMensagem] = useState(false);
  const [pdf, setPdf] = useState(false);

  const [openModalReprovar, setOpenModalReprovar] = useState(false);

  const [linkArquivo, setLinkArquivo] = useState('');

  const buscarImagem = async (linkArquivo) => {
    if (!linkArquivo) return;

    const nome = linkArquivo.split('/').pop();
    setUrl(linkArquivo);
    setLinkArquivo(linkArquivo);
    setColFormat(6);

    if (nome.toLowerCase().includes('.pdf')) {
      setPdf(true);
      setColFormat(12);
    }
  };

  useEffect(() => {
    if (props.location.state) {
      setCpf(props.location.state.cpf);
      validarCpf(props.location.state.cpf);
      if (props.location.state.linkArquivo) {
        buscarImagem(props.location.state.linkArquivo);
      }
    }
  }, [props]);

  const validarCpf = async (cpf) => {
    cpf = cpf.replace(/[^\d]/g, '');
    if (!cpf) return;
    const resultado = await api.get(`/api/parceiros?cpf=${cpf}`);
    if (resultado.data.parceiros.length === 0) {
      toast.error(`CPF inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCpf('');
    } else {
      setParceiro(resultado.data.parceiros[0].nome);
      setCodRevenda(resultado.data.parceiros[0].codRevenda);
      setCnpjRevenda(resultado.data.parceiros[0].cnpjRevenda);
    }
  };

  const validarDataVenda = async (e) => {
    const data = e.target.value.replace(/[^\d]/g, '');
    if (!data) return;
    if (data.length < 8) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setRealizadoEm('');
    }
  };

  const verificarIdentificador = async (e) => {
    const identificador = e.target.value.replace(/[^\d]/g, '');
    if (!identificador || !tipoFiscal) return;
    if (!codRevenda) {
      toast.error(`Informe o CPF do vendedor Loja primeiro`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    const resultado = await api.get(
      `/api/vendas?identificador=${identificador}&tipoFiscal=${tipoFiscal}&codRevenda=${codRevenda}`
    );
    if (resultado.data.vendas.length > 0) {
      toast.error(`Venda já processada`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setIdentificador('');
      setTipoFiscal('');
    }
  };

  const validarCodigo = async (e) => {
    if (!codigo) return;
    if (!codRevenda) {
      toast.error(`Informe o CPF do vendedor Loja primeiro`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }

    const resultado = await api.get(
      `/api/produtos_revenda/produto/${codigo}/${codRevenda}`
    );

    if (!resultado) {
      toast.error(`Produto não encontrado`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCodigo('');
      setNome('');
    } else {
      setNome(resultado.data.nome);
    }
  };

  const calcularSubTotalItem = () => {
    const subTotal =
      Number(valorUnitario) * Number(qtde) - Number(valorDescontoItem);
    setSubTotalItem(subTotal);
  };

  const addItem = (e) => {
    e.preventDefault();
    if (!codigo || !valorUnitario || !qtde) {
      toast.error(`Informe todos os campos para adicionar um item`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (
      Number(valorUnitario) < 0 ||
      Number(qtde) < 1 ||
      Number(valorDescontoItem) < 0
    ) {
      toast.error(`Informe somente valores válidos`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    const item = {
      codigo,
      nome,
      qtde,
      valorUnitario,
      valorDesconto: valorDescontoItem,
      subTotal: subTotalItem,
    };
    setItens([...itens, item]);
    setCodigo('');
    setNome('');
    setValorUnitario('');
    setValorDescontoItem('');
    setQtde('');
    setSubTotalItem('');

    setDescontos(Number(descontos) + Number(valorDescontoItem));
    setTotal(Number(total) + Number(subTotalItem));
  };

  const delItem = (e) => {
    e.preventDefault();
    const posicao = Number(e.target.id);
    setDescontos(Number(descontos) - Number(itens[posicao].valorDesconto));
    setTotal(Number(total) - Number(itens[posicao].subTotal));
    setItens(itens.filter((item, index) => index !== posicao));
  };

  const validarCampos = (dados) => {
    const entries = Object.entries(dados);
    let temNulo = false;

    const camposLabels = {
      cpf: 'CPF',
      tipoFiscal: 'Tipo Fiscal',
      identificador: 'Identificador',
      totalVenda: 'Total da Venda',
      realizadoEm: 'Data da Venda',
      itens: 'Itens',
      linkArquivo: 'Imagem da Nota Fiscal'
    };

    entries.forEach((param) => {
      if (temNulo) return;

      if (param[0] === 'itens' && param[1].length === 0) {
        toast.error('Adicione itens antes de processar', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }

      if (param[0] === 'totalDescontos'   || param[0] === 'totalVenda'|| param[0] === 'enviarMensagem' || param[0] === 'linkArquivo' || param[0] == 'enviarMensagem') {
        return;
      }

      if ((!param[1] || param[1] === '')) {
        const label = camposLabels[param[0]] || param[0];
        toast.error(`${label} é obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });

    return temNulo;
  };

  const processarVenda = async (e) => {
    e.preventDefault();
    setEnviando(true);

    try {
      const venda = {
        cpf,
        tipoFiscal,
        identificador,
        realizadoEm,
        totalVenda: total,
        totalDescontos: descontos,
        itens,
        enviarMensagem,
        linkArquivo,
      };

      const temNulo = validarCampos(venda);
      if (temNulo) {
        setEnviando(false);
        return;
      }

      venda.cpf = venda.cpf.replace(/[^\d]/g, '');
      venda.realizadoEm = venda.realizadoEm.replace(/[^\d]/g, '');
      venda.realizadoEm = `${venda.realizadoEm.substring(
        4
      )}${venda.realizadoEm.substring(2, 4)}${venda.realizadoEm.substring(0, 2)}`;

      const resultado = await api.post('/api/pontuacoes/pontuar', {
        venda,
        enviarMensagem,
      });

      toast.success(resultado.data.mensagem || 'Venda processada com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      setItens([]);
      setCpf('');
      setParceiro('');
      setTipoFiscal('');
      setIdentificador('');
      setRealizadoEm('');
      setCodRevenda('');
      setCnpjRevenda('');
      setTotal('');
      setDescontos('');

      if (url) {
        historico.goBack();
      }

    } catch (error) {
      let mensagemErro = 'Erro ao processar venda';

      if (error.response?.data?.erro) {
        mensagemErro = error.response.data.erro;
      } else if (error.response?.data?.mensagem) {
        mensagemErro = error.response.data.mensagem;
      } else if (error.message) {
        mensagemErro = error.message;
      }

      toast.error(mensagemErro, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setEnviando(false);
    }
  };

  return (
    <Container fluid>
      <div>
        <div className="d-flex flex-row align-items-center">
          <h4 className="pt-3 pb-3 f-black">Nova Venda</h4>
          {url && pdf && (
            <button
              className="btn-primario mt-3 mb-3 px-2 ml-2"
              type="submit"
              onClick={(e) => window.open(url)}
            >
              BAIXAR PDF
            </button>
          )}
        </div>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Row className="w-100">
          <Col
            xs={12}
            lg={colFormat}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <form className="form" onSubmit={processarVenda}>
              <label htmlFor="cpf">CPF</label>
              <InputMask
                id="cpf"
                name="cpf"
                type="text"
                placeholder="CPF"
                value={cpf}
                mask="999.999.999-99"
                onChange={(e) => setCpf(e.target.value)}
                onBlur={(e) => validarCpf(e.target.value)}
              ></InputMask>
              <label htmlFor="cnpjRevenda">CNPJ Revenda</label>
              <InputMask
                id="cnpjRevenda"
                name="cnpjRevenda"
                type="text"
                placeholder="CNPJ"
                value={cnpjRevenda}
                mask="99.999.999/9999-99"
                disabled
              ></InputMask>
              <label htmlFor="parceiro">Vendedor Loja</label>
              <input
                id="parceiro"
                name="parceiro"
                value={parceiro}
                placeholder="Vendedor Loja"
                disabled
              ></input>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="tipoFiscal">Tipo Fiscal</label>
                  <select
                    id="tipoFiscal"
                    name="tipoFiscal"
                    value={tipoFiscal}
                    onChange={(e) => setTipoFiscal(e.target.value)}
                    placeholder="Tipo Fiscal"
                  >
                    <option disabled value="">
                      Tipo Fiscal
                    </option>
                    <option value="N">Nota fiscal</option>
                    <option value="C">Cupom fiscal</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="identificador">Nº Fiscal</label>
                  <InputMask
                    id="identificador"
                    name="identificador"
                    type="text"
                    placeholder="Nº Fiscal"
                    value={identificador}
                    onChange={(e) => setIdentificador(e.target.value)}
                    onBlur={verificarIdentificador}
                  ></InputMask>
                </Col>
              </Row>
              <label htmlFor="realizadoEm">Data da Venda</label>
              <InputMask
                id="realizadoEm"
                name="realizadoEm"
                type="text"
                placeholder="Data da Venda"
                value={realizadoEm}
                onBlur={validarDataVenda}
                onChange={(e) => setRealizadoEm(e.target.value)}
                mask="99/99/9999"
              ></InputMask>
              <label className="mt-4 mb-5 f-18" htmlFor="itens">
                Itens - {`${itens.length} adicionado(s)`}
              </label>
              <Row>
                <SelectProduct
                  className="w-100 mx-3"
                  onChange={(data) => {
                    setCodigo(data.value);
                    const [, ...nome] = data.label.split(' - ');
                    setNome(nome.join(' - '));
                  }}
                ></SelectProduct>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={4}>
                  <label htmlFor="codigo">Código Produto</label>
                  <input
                    disabled
                    type="text"
                    name="codigo"
                    value={codigo}
                    placeholder="Código Produto"
                  />
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={8}>
                  <label htmlFor="nome">Descrição Produto</label>
                  <input
                    type="text"
                    name="nome"
                    value={nome}
                    disabled
                    placeholder="Descrição Produto"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="valorUnitario">R$ Unitário</label>
                  <input
                    type="number"
                    step="0.01"
                    min={0}
                    name="valorUnitario"
                    value={valorUnitario}
                    placeholder="R$ Unitário"
                    onChange={(e) => setValorUnitario(e.target.value)}
                    onBlur={calcularSubTotalItem}
                  />
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="qtde">Qtde</label>
                  <input
                    type="number"
                    step="0.01"
                    name="qtde"
                    value={qtde}
                    min={1}
                    placeholder="Qtde"
                    onChange={(e) => setQtde(e.target.value)}
                    onBlur={calcularSubTotalItem}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="valorDescontoItem">R$ Desconto</label>
                  <input
                    type="number"
                    step="0.01"
                    name="valorDescontoItem"
                    min={0}
                    placeholder="R$ Desconto"
                    onChange={(e) => setValorDescontoItem(e.target.value)}
                    value={valorDescontoItem}
                    onBlur={calcularSubTotalItem}
                  />
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="subTotalItem">R$ Subtotal</label>
                  <input
                    type="number"
                    step="0.01"
                    name="subTotalItem"
                    value={subTotalItem}
                    placeholder="R$ Subtotal"
                    disabled
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-item-center">
                <button className="btn-adicionar mb-3" onClick={addItem}>
                  <i className="fas fa-plus"></i>
                </button>
              </Row>
              <Row>
                <ul className="lista" id="produtos">
                  {itens.map((item, index) => (
                    <li className="lista-item" key={index}>
                      {`${Number(item.qtde).toLocaleString('pt-BR')}x ${item.nome
                        } - ${Number(item.valorUnitario).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })} - Subtotal: ${Number(item.subTotal).toLocaleString(
                          'pt-BR',
                          {
                            style: 'currency',
                            currency: 'BRL',
                          }
                        )} ${item.valorDesconto &&
                        ` (Desconto: ${Number(
                          item.valorDesconto
                        ).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })})`
                        }`}
                      <button onClick={delItem} className="btn-limpo">
                        <i id={index} className="fas fa-trash f-red"></i>
                      </button>
                    </li>
                  ))}
                </ul>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="descontos">R$ Desconto Total</label>
                  <input
                    type="number"
                    step="0.01"
                    name="descontos"
                    min={0}
                    placeholder="R$ Desconto"
                    value={descontos}
                    disabled
                  />
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="total">R$ Total</label>
                  <input
                    type="number"
                    step="0.01"
                    name="total"
                    value={total}
                    placeholder="R$ Total"
                    disabled
                  />
                </Col>
              </Row>
              {enviando ? (
                <button
                  disabled
                  className="btn-primario mt-3 mb-3"
                  type="submit"
                >
                  ENVIANDO...
                </button>
              ) : (
                <button className="btn-primario mt-3 mb-3" type="submit">
                  ENVIAR
                </button>
              )}
              <button
                className="btn-secundario mt-3 mb-3"
                onClick={() => setOpenModalReprovar(true)}
                type="button"
              >
                REPROVAR
              </button>
            </form>
          </Col>
          <Col xs={12} lg={6} id="container-img">
            {url && !pdf && (
              <Viewer
                visible={true}
                container={document.getElementById('container-img')}
                images={[{ src: url, alt: 'Envio' }]}
                noNavbar
                noImgDetails
                noClose
                zoomSpeed={0.5}
                attribute={false}
              />
            )}
          </Col>
        </Row>
        {openModalReprovar && (
          <ModalReprovarNota
            show={openModalReprovar}
            onHide={() => setOpenModalReprovar(false)}
            linkArquivo={linkArquivo}
          />
        )}
      </div>
    </Container>
  );
}

export default withRouter(NovaVenda);
