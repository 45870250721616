import React, {useEffect,useState} from 'react';
import Quagga from 'quagga';
import './style.css';
import api from '../../../../../services/API';
import { toast } from 'react-toastify';

const Scanner = (props) => {
  const [isOnCamera, setIsOnCamera] = useState(false);
  const [barcode, setBarCode] = useState('');

  function startCamera() {
    Quagga.init({
      inputStream : {
        type : "LiveStream",
        constraints: {
          width: 248,
          height: 248,
        },
        singleChannel: false
      },
      locator: {
        patchSize: "small",
        halfSample: true
      },
      numOfWorkers: 2,
      decoder : {
        readers : ["upc_reader"]
      },
      locate: false
    }, function(err) {
        if (err) {
            console.log(err);
            return
        }
        if(isOnCamera) {
          Quagga.stop();
        } else {
          Quagga.start();

        }
    });
    Quagga.onDetected(onDetected);
  }

  const onDetected = ({codeResult}) => {
    verifyCode(codeResult);
  }

  useEffect(() => {
    startCamera();
  }, [isOnCamera])


  const verifyCode = async (codeResult) => {
    try {
      const {data} = await api.get(`api/produtos/barcode/${codeResult.code}`);
      if(!data[0].length > 0) {
        toast.error("Não há produto com esse código!", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        return;
      }
      props.handleInsertCode(data[0][0].codigo);
    }
    catch(error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
  }

  return <div style={{
      display: 'flex',
      flexDirection:'column',
      padding: '16px',
      alignItems: 'center',
      justifyContent: 'center',
      rowGap: '32px',
      maxWidth: '64%',
    }}>
    <div style={{display: 'flex', columnGap: '16px'}} >
     <button className="btn-enviar" style={{ height: 'auto' }} onClick={() => setIsOnCamera(!isOnCamera)}>
        {!isOnCamera ? 'Desligar Câmera' : 'Ligar Câmera'}
      </button>
      {/* <button className="btn-enviar" onClick={() => setBarCode("")}>
        Ler novamente
      </button> */}
    </div>

    <div
      id="interactive"
      className="viewport"
    />
  </div>
  ;
}

export default Scanner;
