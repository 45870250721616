import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import ModalEncerrarPontuacao from '../ModalEncerrarPontuacao';
import ModalMidia from '../ModalMidia';
import ModalReprovarNota from '../ModalReprovarNota';

const { SearchBar } = Search;

export default function PontuacoesPendente() {
  const [mostrarModalFoto, setMostrarModalFoto] = useState(false);
  const [linkArquivo, setLinkArquivo] = useState(null);
  const [tipo, setTipo] = useState([]);
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [mostrarModalEncerrarPontuacao, setMostrarModalEncerrarPontuacao] =
    useState(false);
  const [pontuacao, setPontuacao] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [filterBy, setFilterBy] = useState('PARCEIRO');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [mostrarModalReprovarPontuacao, setMostrarModalReprovarPontuacao] = useState(false)

  useEffect(() => {
    buscarPontuacoes();
  }, [page, perPage, search, filterBy, sortField, sortOrder]);

  const visualizarModalMidia = (linkArquivo, tipo) => {
    if (!linkArquivo) return;

    if (tipo !== 'image') {
      window.open(linkArquivo, '_blank');
    } else {
      setLinkArquivo(linkArquivo);
      setTipo(tipo);
      setMostrarModalFoto(true);
    }
  };

  const fecharModalFoto = () => {
    setMostrarModalFoto(false);
  };

  const columns = [
    {
      text: 'Vendedor Loja',
      dataField: 'parceiro',
      sort: true,
    },
    {
      text: 'CPF',
      dataField: 'cpf',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
      sort: true,
    },
    {
      dataField: 'cnpj',
      text: 'CNPJ',
      formatter: (celula, valor) => {
        let cnpj = valor.cnpj.replace(/[^\d]/g, '');
        return cnpj.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5'
        );
      },
      sort: true,
    },
    {
      text: 'Código Loja',
      dataField: 'codRevenda',
      sort: true,
    },
    {
      text: 'Loja',
      dataField: 'revenda',
      sort: true,
    },
    {
      text: 'Data do Envio',
      dataField: 'criadoEm',
      formatter: (celula, valor) => {
        return moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Foto da Venda',
      dataField: 'linkArquivo',
      formatter: (celula, valor) => {
        if (valor.linkArquivo) {
          return (
            <button
              className="btn-limpo f-12 p-1"
              onClick={() => {
                visualizarModalMidia(valor.linkArquivo, valor.tipoArquivo);
              }}
            >
              <i className="fas fa-image"></i>
            </button>
          );
        } else {
          return (
            <button className="btn-limpo f-12 p-1" disabled>
              SEM FOTO
            </button>
          );
        }
      },
    },
    {
      text: 'Processar Pontuação',
      dataField: '',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo"
            onClick={(e) =>
              processarPontuacao(valor.cpf, valor.linkArquivo)
            }
          >
            <i className="fas fa-check f-green"></i>
          </button>
        );
      },
    },
    {
      text: 'Encerrar Pontuação',
      dataField: 'id',
      formatter: (celula, valor) => {
        return (
          <button
            onClick={() => {
              visualizarModalEncerrarPontuacao(valor.id);
            }}
            className="btn-limpo"
          >
            <i className="fas fa-times f-red"></i>
          </button>
        );
      },
    },
    {
      text: 'Reprovar Pontuação',
      dataField: 'id',
      formatter: (celula, valor) => {
        return (
          <button
            onClick={() => {
              visualizarModalReprovarPontuacao(valor.id, valor.linkArquivo);
            }}
            className="btn-limpo"
          >
            <i className="fas fa-times f-red"></i>
          </button>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'criadoEm',
      order: 'desc',
    },
  ];

  const buscarPontuacoes = async () => {
    setBuscando(true);
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('perPage', perPage);
    if (search) {
      params.append('search', search);
      params.append('filterBy', filterBy);
    }
    if (sortField) {
      params.append('sortField', sortField);
      params.append('sortOrder', sortOrder);
    }
    const resultado = await api.get(
      `/api/pontuacoes/pendentes?${params.toString()}`
    );
    if (resultado) {
      setData(resultado.data.pontuacao);
      setTotalRecords(resultado.data.totalRecords);
    }
    setBuscando(false);
  };

  const processarPontuacao = (cpf, linkArquivo) => {
    historico.push('/painel/processar/nova-venda', {
      cpf,
      linkArquivo,
    });
  };

  const visualizarModalEncerrarPontuacao = (id) => {
    setPontuacao(id);
    setMostrarModalEncerrarPontuacao(true);
  };

  const visualizarModalReprovarPontuacao = (id, linkArquivo) => {
    setPontuacao(id);
    setLinkArquivo(linkArquivo);
    setMostrarModalReprovarPontuacao(true);
  };

  const fecharModalEncerrarPontuacao = () => {
    setMostrarModalEncerrarPontuacao(false);
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
      setTotalRecords(0);
    }
  }, [buscando]);

  const options = {
    page,
    sizePerPage: perPage,
    totalSize: totalRecords,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: totalRecords,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {(page - 1) * perPage + 1}-{perPage} de {totalRecords} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações Pendentes</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={4} className="d-flex flex-column">
          <label htmlFor="filterBy">Buscar Por</label>
          <select
            className="input-select mb-3"
            name="filterBy"
            id="filterBy"
            onChange={(e) => {
              setFilterBy(e.target.value);
            }}
            value={filterBy}
          >
            <option value="PARCEIRO">Vendedor Loja</option>
            <option value="CPF">CPF</option>
            <option value="CNPJ">CNPJ</option>
            <option value="CODIGO">Código Loja</option>
            <option value="LOJA">Loja</option>
          </select>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                remote
                onTableChange={(action, options) => {
                  if (action === 'pagination') {
                    setPage(options.page);
                    setPerPage(options.sizePerPage);
                  } else if (action === 'search') {
                    setPage(1);
                    setSearch(options.searchText || '');
                  } else if (action === 'sort') {
                    setSortField(options.sortField);
                    setSortOrder(options.sortOrder);
                  }
                }}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalMidia
        show={mostrarModalFoto}
        onHide={fecharModalFoto}
        linkArquivo={linkArquivo}
        tipo={tipo}
      />
      <ModalEncerrarPontuacao
        show={mostrarModalEncerrarPontuacao}
        onHide={fecharModalEncerrarPontuacao}
        pontuacao={pontuacao}
      />
      <ModalReprovarNota
        show={mostrarModalReprovarPontuacao}
        onHide={() => setMostrarModalReprovarPontuacao(false)}
        linkArquivo={linkArquivo}
      >
      </ModalReprovarNota>
    </Container>
  );
}
