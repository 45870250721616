import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarParceiro } from '../../../store/modules/autenticacao/actions';
import Footer from '../../../components/Loja/Footer';
import { Link } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLogin from '../../../components/Loja/GoogleLogin';
import Header from '../../../components/Loja/Header';

export default function Login() {
  const dispatch = useDispatch();
  const enviando = useSelector((state) => state.autenticacao.autenticandoLoja);

  useEffect(() => {
    document.title = 'Experiências SW - Login';
  }, []);

  const solicitarAutenticacao = ({ cpf, senha }) => {
    dispatch(autenticarParceiro(cpf?.replace(/\.|\-/g, ''), senha));
  };

  return (
    <>
      <Header auto />
      {/* <Link to="/">
        <img
          className="w-100  img-fluid"
          src="imagens/banner-experiencias-sw.jpg"
          alt="Experiências SW"
        ></img>
      </Link> */}
      <Container className="d-flex flex-column justify-content-center">
        <Row className="justify-content-center mt-5 mb-5">
          <Form
            onSubmit={solicitarAutenticacao}
            className="form-login form-login-loja"
          >
            <h5 className="m-auto">FAÇA SEU LOGIN</h5>
            <label>Entre com seus dados e acesse sua conta</label>

            {/* <div id="googlebtn" style={{ paddingTop: '16px', width: '100%' }}>
              <GoogleOAuthProvider clientId="425931863053-i3tf8n6mrujvl5slcs7pr4ql48po0eg8.apps.googleusercontent.com">
                <GoogleLogin></GoogleLogin>
              </GoogleOAuthProvider>
            </div> */}

            <label htmlFor="cpf">CPF</label>
            <Input name="cpf" type="text" placeholder="CPF"></Input>
            <label htmlFor="senha">Senha</label>
            <Input name="senha" type="password" placeholder="Senha"></Input>
            {enviando ? (
              <button disabled className="btn-primario" type="submit">
                VALIDANDO...
              </button>
            ) : (
              <button className="btn-primario" type="submit">
                ENTRAR
              </button>
            )}
            <Link
              className="mb-3 mt-3 text-center link-clean"
              to="/esqueceu-senha"
            >
              Esqueceu sua senha?
            </Link>
          </Form>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
