import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import historico from '../../../services/Historico';

export default function ModalReprovarNota(props) {
  const [motivo, setMotivo] = useState('');
  const [enviando, setEnviando] = useState(false);
  const usuario = useSelector((state) => {
    return state.usuario;
  });
  async function handleSubmit(e) {
    e.preventDefault();

    setEnviando(true);
    const resultado = await api.post('/api/pontuacoes/reprovar', {
      linkArquivo: props.linkArquivo,
      reprovadoMotivo: motivo,
      reprovadoPor: usuario.nome,
    });
    if (resultado && resultado.data) {
      toast.error(resultado.data.mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
      historico.goBack();
    } else {
      toast.error('Ocorreu um erro ao reprovar a nota', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Especifique o Motivo</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Row className="w-100">
          <Col
            className="d-flex flex-column justify-content-center align-items-center mb-3 mt-3"
            xs={12}
          >
            <select
              className="w-50 input-select"
              id="motivo"
              name="motivo"
              value={motivo}
              onChange={(e) => setMotivo(e.target.value)}
            >
              <option disabled value="">
                Especifique o Motivo
              </option>
              <option value="Nota Duplicada">Duplicado</option>
              <option value="Nota Ilegível">Ilegível</option>
              <option value="Nota Incompleta">Incompleta</option>
              <option value="Nota Não Qualificada">Não Qualificada</option>
              <option value="CNPJ divergente do cadastro">CNPJ divergente do cadastro</option>
              <option value="Data fora do mês vigente">Data fora do mês vigente</option>
            </select>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-primario px-2 mt-3 mb-3" onClick={handleSubmit}>
          ENVIAR
        </button>

        <button
          className="btn-secundario px-2 mt-3 mb-3"
          onClick={props.onHide}
        >
          CANCELAR
        </button>
      </Modal.Footer>
    </Modal>
  );
}
