/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ModalItensPontuacao from '../ModalItensPontuacao';
import ModalMidia from '../ModalMidia';
import ModalCancelarPontuacao from '../ModalCancelarPontuacao';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';

const { SearchBar } = Search;

export default function Pontuacoes() {
  const [buscando, setBuscando] = useState(false);
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState([]);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(7, 'days').toISOString().substr(0, 10)
  );
  const [dataFinal, setDataFinal] = useState(
    moment().toISOString().substr(0, 10)
  );
  const [data, setData] = useState([]);
  const [mostrarModalItens, setMostrarModalItens] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [mostrarModalFoto, setMostrarModalFoto] = useState(false);
  const [linkArquivo, setLinkArquivo] = useState(null);
  const [tipo, setTipo] = useState([]);
  const [mostrarModalCancelarPontuacao, setMostrarModalCancelarPontuacao] =
    useState(false);
  const [pontuacao, setPontuacao] = useState('');
  const [searchText, setSearchText] = useState('');
  const [toolkitProps, setToolkitProps] = useState(null);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    buscarRedes();
    buscarPontuacoes();
  }, []);

  const buscarRedes = async () => {
    try {
      const resultado = await api.get('/api/redes?ativo=1');
      if (resultado) {
        setRedesSelect(resultado.data.redes);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const columns = [
    { text: 'ID', dataField: 'id', sort: true },
    {
      text: 'Vendedor Loja',
      dataField: 'parceiro',
      sort: true,
    },
    {
      text: 'Loja',
      dataField: 'revenda',
      sort: true,
    },
    {
      text: 'Cidade',
      dataField: 'cidade',
      sort: true,
    },
    {
      text: 'Nº Fiscal',
      dataField: 'identificador',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      text: 'Pontos',
      dataField: 'totalPontos',
      formatter: (celula, valor) => {
        return Number(valor.totalPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      text: 'Valor Custo',
      dataField: 'valorCusto',
      formatter: (celula, valor) => {
        return Number(valor.valorCusto)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      text: 'Data da Venda',
      dataField: 'realizadoEm',
      formatter: (celula, valor) => {
        return moment(valor.realizadoEm).format('DD/MM/YYYY');
      },
      sort: true,
    },
    {
      text: 'Data da Pontuação',
      dataField: 'pontuadoEm',
      formatter: (celula, valor) => {
        return moment(valor.pontuadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Validade dos Pontos',
      dataField: 'venceEm',
      formatter: (celula, valor) => {
        return moment(valor.venceEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Itens da Pontuação',
      dataField: 'itens',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => visualizarModalItens(valor.itens)}
          >
            <i className="fas fa-list"></i>
          </button>
        );
      },
    },
    {
      text: 'Foto da Venda',
      dataField: 'linkArquivo',
      formatter: (celula, valor) => {
        if (valor.linkArquivo) {
          return (
            <button
              className="btn-limpo f-12 p-1"
              onClick={() => {
                visualizarModalMidia(valor.linkArquivo, valor.tipoArquivo);
              }}
            >
              <i className="fas fa-image"></i>
            </button>
          );
        } else {
          return (
            <button className="btn-limpo f-12 p-1" disabled>
              SEM FOTO
            </button>
          );
        }
      },
    },
    {
      text: 'Cancelar Pontuação',
      dataField: 'id',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalCancelarPontuacao(valor.id);
            }}
          >
            <i className="fas fa-times f-red"></i>
          </button>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'pontuadoEm',
      order: 'desc',
    },
  ];

  const buscarPontuacoes = async () => {
    setBuscando(true);
    try {
      const url = `/api/pontuacoes?rede=${redeSelecionada}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&page=${page}&perPage=${sizePerPage}`;
      
      const resultado = await api.get(url);

      if (resultado?.data?.pontuacoes) {
        setData(resultado.data.pontuacoes || []);
        setTotalSize(resultado.data.totalRecords || 0);
      } else {
        setData([]);
        setTotalSize(0);
      }
    } catch (error) {
      setData([]);
      setTotalSize(0);
    }
    setBuscando(false);
  };

  useEffect(() => {
    buscarPontuacoes();
  }, [page, sizePerPage, redeSelecionada]);

  const visualizarModalItens = (produtos) => {
    setProdutos(produtos);
    setMostrarModalItens(true);
  };

  const fecharModalItens = () => {
    setMostrarModalItens(false);
  };

  const visualizarModalMidia = (linkArquivo, tipo) => {
    if (!linkArquivo) return;

    if (tipo !== 'image') {
      window.open(linkArquivo, '_blank');
    } else {
      setLinkArquivo(linkArquivo);
      setTipo(tipo);
      setMostrarModalFoto(true);
    }
  };

  const fecharModalFoto = () => {
    setMostrarModalFoto(false);
  };

  const visualizarModalCancelarPontuacao = (id) => {
    setPontuacao(id);
    setMostrarModalCancelarPontuacao(true);
  };

  const fecharModalCancelarPontuacao = () => {
    setMostrarModalCancelarPontuacao(false);
  };

  const options = {
    sizePerPage: sizePerPage,
    page: page,
    totalSize: totalSize,
    showTotal: true,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ],
    onPageChange: (newPage, newSizePerPage) => {
      setPage(newPage);
    },
    onSizePerPageChange: (newSizePerPage, newPage) => {
      setSizePerPage(newSizePerPage);
      setPage(newPage);
    },
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total px-2">
        {size > 0 ? `${from}-${to} de ${size} resultados` : 'Nenhum resultado'}
      </span>
    )
  };

  const handleSearch = () => {
    if (toolkitProps) {
      toolkitProps.searchProps.onSearch(searchText);
    }
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="align-items-center mb-3">
        <Col xs={12} sm={4}>
          <div>
            <label className="mb-1">Selecione uma Rede</label>
            <select
              className="input-select"
              name="redeSelecionada"
              id="redeSelecionada"
              onChange={(e) => setRedeSelecionada(e.target.value)}
              defaultValue=""
              style={{ height: '38px', width: '100%' }}
            >
              <option value="">Todas</option>
              {redesSelect.map((rede) => (
                <option value={rede.nome} key={rede.nome}>
                  {rede.nome}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col xs={12} sm={2}>
          <div>
            <label className="mb-1">De</label>
            <input
              type="date"
              className="input-theme"
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
              max="2999-12-31"
              style={{ height: '38px', width: '100%' }}
            />
          </div>
        </Col>
        <Col xs={12} sm={2}>
          <div>
            <label className="mb-1">Até</label>
            <input
              type="date"
              className="input-theme"
              onChange={(e) => setDataFinal(e.target.value)}
              value={dataFinal}
              max="2999-12-31"
              style={{ height: '38px', width: '100%' }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1}>
          <div style={{ marginTop: '24px' }}>
            <button
              className="btn-busca"
              type="submit"
              onClick={buscarPontuacoes}
              style={{ height: '38px' }}
            >
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                <i className="fas fa-search"></i>
              )}
            </button>
          </div>
        </Col>
        <Col xs={12} sm={3} className="d-flex justify-content-end">
          <div style={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: '8px',
            marginTop: '24px'
          }}>
            <input
              type="text"
              placeholder="Buscar"
              className="input-theme"
              style={{ 
                width: '200px',
                height: '38px'
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              className="btn-busca"
              type="submit"
              onClick={handleSearch}
              style={{ 
                height: '38px',
                minWidth: '38px',
                padding: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <i className="fas fa-search"></i>
            </button>
          </div>
        </Col>
      </Row>
      
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => {
            if (!toolkitProps) {
              setToolkitProps(props);
            }
            return (
              <div>
                <BootstrapTable
                  classes="table-dark text-center rounded"
                  wrapperClasses="rounded"
                  bordered={false}
                  pagination={paginationFactory(options)}
                  defaultSorted={defaultSorted}
                  noDataIndication={() =>
                    buscando ? (
                      <span>
                        Carregando
                        <i className="fas fa-circle-notch fa-spin ml-3"></i>
                      </span>
                    ) : (
                      <span>Sem resultados</span>
                    )
                  }
                  {...props.baseProps}
                />
              </div>
            );
          }}
        </ToolkitProvider>
      </div>
      <ModalItensPontuacao
        show={mostrarModalItens}
        onHide={fecharModalItens}
        produtos={produtos}
      />
      <ModalMidia
        show={mostrarModalFoto}
        onHide={fecharModalFoto}
        linkArquivo={linkArquivo}
        tipo={tipo}
      />
      <ModalCancelarPontuacao
        show={mostrarModalCancelarPontuacao}
        onHide={fecharModalCancelarPontuacao}
        pontuacao={pontuacao}
      />
    </Container>
  );
}
