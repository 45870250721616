/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import './styles.css';
import { LoaderContext } from '../../Loader';
import { toast } from 'react-toastify';

export default function Experiencias() {
  const [category, setCategory] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const { setIsLoading, isLoading } = useContext(LoaderContext);
  const [filters, setFilters] = useState({
    subcategories: [],
  });

  const url = new URLSearchParams(window.location.search);
  const page = url.get('page');
  const categoriaURL = url.get('categoria');

  useEffect(() => {
    if (!categoriaURL) historico.push('/home');
    document.title = 'Experiências SW - Loja';
    loadCategory();
    loadExperiences();
    handleSearchParams();
  }, []);

  const loadCategory = async () => {
    try {
      const { data } = await api.get('/api/tlc-categories');

      if (data) {
        const aux = data.categorias.find(
          (item) => item.id === Number(categoriaURL)
        );
        if (aux) setCategory(aux);
        else
          toast.error('Categoria não encontrada.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          });
      }
    } catch (erro) {
      console.error('erro: ', erro);
      toast.error('Erro interno.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
  };

  const loadExperiences = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams();
    queryParams.append('categories', categoriaURL);
    const _subcategories = url.getAll('subcategories');
    if (_subcategories)
      queryParams.append(
        'subcategories',
        _subcategories.map((sub) => sub).join(',')
      );

    try {
      const resultado = await api.get(
        `/api/tlc-experiences?${queryParams.toString()}`
      );

      if (resultado) {
        setExperiences(resultado.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchParams = () => {
    const subcategories = url.getAll('subcategories');

    if (subcategories) {
      setFilters((prev) => ({ ...prev, subcategories }));
    }
  };

  const handleSubcategorySelect = async (id) => {
    const queryParams = new URLSearchParams(window.location.search);
    const _subcategories = queryParams.getAll('subcategories');
    if (_subcategories) {
      if (_subcategories.includes(String(id))) {
        queryParams.delete('subcategories', id);
      } else {
        queryParams.append('subcategories', id);
      }
    }
    url.set('subcategories', queryParams.toString());
    historico.push(`/experiencias?${queryParams.toString()}`);
  };

  function handleExperienceSelect(e, id) {
    e.preventDefault();

    const queryParams = new URLSearchParams();
    queryParams.append('experience', id);
    queryParams.append('categoria', categoriaURL);

    historico.push(`/loja?${queryParams.toString()}`);
  }

  function limparFiltros() {
    const currentUrl = new URLSearchParams(window.location.search);
    currentUrl.delete('subcategories');
    historico.push(`/experiencias?${currentUrl.toString()}`);
  }

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          display: 'grid',
          placeContent: 'center',
          padding: '64px',
        }}
      >
        <p>Carregando...</p>
      </div>
    );
  }

  if (experiences.length === 0) {
    return (
      <div
        style={{
          width: '100%',
          display: 'grid',
          placeContent: 'center',
          padding: '64px',
        }}
      >
        <p>Nenhuma oferta encontrada...</p>
      </div>
    );
  }

  return (
    <Container className="mb-5">
      <Breadcrumb>
        <a href="/home">CATEGORIA: {category.nome}</a>
      </Breadcrumb>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <Row className="sticky">
            <Col xs={12}>
              <h5>SUB-CATEGORIAS</h5>
              <hr className="hr-style" />
            </Col>
            <Col xs={12}>
              <ul className="list-unstyled">
                {category?.subcategories?.map((subcategory) => {
                  return (
                    <li
                      className="categoria"
                      onClick={() => {
                        handleSubcategorySelect(subcategory.id);
                      }}
                      key={subcategory.id}
                      data-id={subcategory.id}
                    >
                      {filters.subcategories.includes(
                        subcategory.id.toString()
                      ) ? (
                        <i className="far fa-check-square mr-3"></i>
                      ) : (
                        <i className="far fa-square mr-3"></i>
                      )}
                      {subcategory.name}
                    </li>
                  );
                })}
              </ul>
              <div className="limpar-filtros">
                <i className="fas fa-trash mr-3"></i>
                <button className="btn-limpo" onClick={limparFiltros}>
                  Limpar filtros
                </button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={8} lg={9}>
          <div>
            <h5>EXPERIÊNCIAS</h5>
            <hr className="hr-style" />
          </div>
          <Row>
            {experiences.map((experience) => (
              <Col xs={12} md={4} lg={3} className="mt-5" key={experience.id}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <img
                      className="w-100"
                      src={experience.mainImageUrl}
                      alt=""
                    />
                  </div>
                  <div>{experience.title}</div>
                  <div style={{ width: '100%' }}>
                    <button
                      className="btn-primario"
                      style={{ width: '100%' }}
                      onClick={(e) => handleExperienceSelect(e, experience.id)}
                    >
                      Selecionar
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
