/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { Modal } from 'react-bootstrap';
import 'react-image-lightbox/style.css';

export default function ModalMidia(props) {
  const [modal, setModal] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.show) {
      gerarModal();
    } else {
      setError(null);
    }
  }, [props.show, props.linkArquivo, props.tipoArquivo]);

  const fecharModal = () => {
    setModal('');
    setError(null);
    props.onHide();
  };

  const gerarModal = () => {
    if (!props.linkArquivo) {
      setError('Arquivo não encontrado');
      return;
    }

    const imageUrl = `${props.linkArquivo}?t=${Date.now()}`;

    if (props.tipoArquivo?.includes('image')) {
      const img = new Image();
      img.crossOrigin = 'anonymous';

      img.onload = () => {
        setModal(
          <Lightbox
            mainSrc={imageUrl}
            onCloseRequest={fecharModal}
            enableZoom={true}
            imageCrossOrigin="anonymous"
          />
        );
      };

      img.onerror = (e) => {
        console.error('Erro ao carregar imagem:', e);
        setError('Não foi possível carregar a imagem');
        setModal(
          <Modal
            show={props.show}
            onHide={fecharModal}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Erro</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <div className="alert alert-danger">
                {error || 'Erro ao carregar imagem'}
              </div>
            </Modal.Body>
          </Modal>
        );
      };

      img.src = imageUrl;
    } else if (props.tipoArquivo?.includes('video')) {
      setModal(
        <Modal
          show={props.show}
          onHide={fecharModal}
          size="lg"
          centered
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Visualização do Vídeo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video
              controls
              src={imageUrl}
              style={{ width: '100%' }}
              crossOrigin="anonymous"
              onError={() => setError('Não foi possível carregar o vídeo')}
            />
            {error && (
              <div className="alert alert-danger mt-3">
                {error}
              </div>
            )}
          </Modal.Body>
        </Modal>
      );
    } else {
      setError('Tipo de arquivo não suportado');
    }
  };

  if (error && !modal) {
    return (
      <Modal
        show={props.show}
        onHide={fecharModal}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Erro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-danger">
            {error}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return modal;
}
