import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [pontos, setPontos] = useState(props.data?.pontos);

  const atualizar = async (e) => {
    e.preventDefault();

    const dataUpdated = {
      pontos,
    };

    setEditando(true);
    const resultado = await api.put(
      `/api/ofertas/${props.data?.id}`,
      dataUpdated
    );

    if (resultado) {
      toast.success(`Oferta alterado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
      props.atualizarOferta({ ...dataUpdated, id: props.data?.id });
    }
    setEditando(false);
  };

  useEffect(() => {
    if (props.data) {
      setPontos(props.data?.pontos);
    }
  }, [props]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div className="d-flex flex-column align-items-center">
          <form className="form" onSubmit={atualizar}>
            <label htmlFor="pontos">Pontos</label>
            <input
              id="pontos"
              name="pontos"
              type="number"
              step="0.01"
              placeholder="Pontos"
              value={pontos}
              onChange={(e) => setPontos(e.target.value)}
            ></input>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {editando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
