import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import api from '../../../services/API';

export default function Regulamento() {
  const [cenario, setCenario] = useState(null);
  const parceiro = useSelector((state) => state.parceiro);

  const dadosRevenda = async () => {
    const resposta = await api
      .get(`/api/revendas/cnpj/${parceiro.cnpjRevenda}`)
      .catch((err) => console.log('Erro na consulta da revenda', err.message));

    if (resposta) {
      setCenario(resposta.data.cenario);
    }
  };

  useEffect(() => {
    dadosRevenda();
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>TERMOS E CONDIÇÕES PROGRAMA DE FIDELIDADE - EXPERIÊNCIA SW</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div className="text-justify">
        <div>
          <p>
            Sherwin-Williams do Brasil Indústria e Comércio Ltda., inscrita no
            CNPJ sob nº 34.274.233/0001-02, com sede na Avenida Ibirama, 480,
            Jardim São Judas Tadeu, na Cidade de Taboão da Serra, Estado de São
            Paulo, CEP 20211-140, doravante denominada simplesmente
            Sherwin-Williams (“<strong>PROMOTORA</strong>”), com base no
            presente Termos e Condições, institui o{' '}
            <strong>
              PROGRAMA DE FIDELIDADE EXPERIÊNCIA SW (“EXPERIÊNCIA SW”)
            </strong>
            , conforme os termos, cláusulas e condições a seguir estipulados.
          </p>
          <p>
            Como a <strong>EXPERIÊNCIA SW</strong> é um programa instituído pela
            Sherwin-Williams e gerida e mantida pela Smart Tecnologia em
            Vendas(“Smart”), fica estabelecido que, os dados pessoais de
            cadastro dos interessados em participar deste programa, também
            poderão ser compartilhados com entre estas empresas e, a adesão aos
            seus respectivos regulamentos e políticas é necessária e gratuita,
            para que seja possível acúmulo e resgate de pontos no Programa.
          </p>
          <p>
            A Smart é a detentora tanto da plataforma como também do site
            destinado a este Programa.
          </p>
          <p>
            Este Termos e Condições não substitui os Termos e Condições Gerais,
            estabelecidos pela Sherwin-Williams.
          </p>
          <p>
            A <strong>PROMOTORA</strong> se reserva no direito de, segundo seu
            critério, modificar o presente Termos e Condições a qualquer
            momento, com ou sem prévio aviso.
          </p>
          <br />
          <p>
            <strong>ÍNDICE</strong>
          </p>
          <ol>
            <li>
              <a href="#1">Definições</a>
            </li>
            <li>
              <a href="#2">
                Participação e adesão ao <strong>EXPERIÊNCIA SW</strong>
              </a>
            </li>
            <li>
              <a href="#3">Acúmulo de Pontos</a>
            </li>
            <li>
              <a href="#4">Resgate de Pontos</a>
            </li>
            <li>
              <a href="#5">
                Cancelamento do <strong>EXPERIÊNCIA SW</strong>
              </a>
            </li>
            <li>
              <a href="#6">Política de Proteção de Dados Pessoais</a>
            </li>
            <li>
              <a href="#7">Considerações Gerais</a>
            </li>
            <li>
              <a href="#8">Registro</a>{' '}
            </li>
          </ol>
          <br />
        </div>
        <p>
          <ol>
            <li id="1">
              <strong>DEFINIÇÕES</strong>
              <br />
              <br />
              <ol>
                <li>
                  Neste Termos e Condições, palavras e expressões abaixo
                  listadas, quando escritas em letra maiúscula, possuem
                  respectivamente o significado indicado:
                  <br />
                  <br />
                  <strong>
                    a. PROGRAMA DE FIDELIDADE EXPERIÊNCIA SW ou, simplesmente,
                    EXPERIÊNCIA SW:
                  </strong>{' '}
                  é um programa de fidelização da Sherwin-Williams, com vigência
                  por prazo indeterminado, que permite aos Participantes o
                  acúmulo de Pontos, em razão da venda de Produtos Elegíveis e
                  que serão utilizados e resgatados exclusivamente para a
                  obtenção de benefícios ou aquisição de itens constante no
                  Catálogo disponibilizado no site.
                  <br />
                  <br />
                  <strong>b. Participantes: </strong> toda e qualquer pessoa
                  física, maior de 18 (dezoito) anos, inscrita e com cadastro
                  ativo e regular no Cadastro de Pessoas Físicas (CPF) do
                  Ministério da Fazenda, residente e domiciliada no território
                  nacional, que, habitualmente vendam tintas ou outros produtos
                  da linha de fabricação da Promotora (respectivamente,
                  "Vendedores e ou Balconistas" e “Produtos”) e, que venha a se
                  cadastrar no <strong>Experiência SW</strong>, nas revendas
                  participantes, nos termos deste Termos e Condições.
                  <br />
                  <br />
                  <strong>c. Pontos</strong> é a unidade de medida utilizada
                  para contabilizar os acúmulos para os resgates realizados pelo
                  Participante no <strong>Experiência SW</strong>.
                  <br />
                  <br />
                  <strong>d. Produtos Elegíveis: </strong> são os produtos da
                  linha de fabricação pela Sherwin-Williams, que possibilitarão
                  o acúmulo de pontos com o posterior regaste ao Participante,
                  desde que realizada a adesão ao{' '}
                  <strong>Experiência SW</strong> e aceite as condições do
                  presente Termos e Condições.
                  <br />
                  <br />
                  <strong>e. Catálogo: </strong>é o inventário de produtos
                  disponibilizados na plataforma da Smart para resgate. O
                  Catálogo de Produtos pode ser, a qualquer tempo alterado pela
                  Smart e Sherwin-Williams, segundo seu exclusivo critério, sem
                  aviso prévio, cabendo ao Participante consultar os Produtos
                  disponíveis e condições vigentes no momento do resgate.
                  <br />
                  <br />
                  <strong>f. Central de Atendimento: </strong>Central de
                  Atendimento: é o canal de atendimento, disponível no através
                  de WhatsApp, telefone e e-mail, para o Participante esclarecer
                  dúvidas, realizar reclamações e elogios referente ao{' '}
                  <strong>Experiência SW</strong>.
                  <br />
                  <br />
                  <strong>g. Site:</strong> endereço eletrônico:{' '}
                  <a href="www.experienciasw.com.br">
                    www.experienciasw.com.br
                  </a>
                  <strong>h. Revendas Participantes:</strong> são revendas
                  disponibilizadas no site do <strong>Experiência SW</strong>{' '}
                  onde será possível o acúmulo de Pontos pelos Participantes. As
                  Revendas Participantes poderão sofrer alteração, a qualquer
                  tempo, sendo de responsabilidade exclusiva dos Participantes
                  consultar regularmente o Site.
                </li>
              </ol>
            </li>
            <br />

            <li id="2">
              <strong>PARTICIPAÇÃO CADASTRO E ADESÃO AO EXPERIÊNCIA SW</strong>
              <br />
              <br />
              <ol>
                <li>
                  O Cliente Participante que queira aderir ao{' '}
                  <strong>Experiência SW</strong>
                  declara que está de acordo e aceita os Temos deste Termos e
                  Condições, a Política de Privacidade da Promotora e da Smart,
                  que estarão disponíveis no site.
                </li>
                <br />
                <li>
                  Para fins deste Programa, o Participante somente poderá ser
                  titular de um único cadastro pessoal e intransferível no{' '}
                  <strong>Experiência SW</strong>, sendo também o único
                  responsável por garantir a veracidade das informações
                  preenchidas e mantê-las atualizadas.
                </li>
                <br />
                <li>
                  O Participante não poderá ser titular de mais de um cadastro
                  pessoal no <strong>Experiência SW</strong>
                </li>
                <br />
                <li>
                  Para a adesão ao <strong>Experiência SW</strong>, o
                  Participante deverá realizar o seu cadastro por meio do site,
                  onde deverá preencher os seguintes campos:
                  <br />
                  <br />
                  a. nome completo;
                  <br />
                  b. data de nascimento;
                  <br />
                  c. número de celular;
                  <br />
                  d. CPF/ME;
                  <br />
                  e. CNPJ da loja
                  <br />
                  f. Razão Social
                  <br />
                  g. e-mail;
                  <br />
                  h. gênero;
                  <br />
                  i. CEP; e
                  <br />
                  j. Endereço.
                  <br />
                  <br />
                  <ol>
                    <li>
                      A senha utilizada para login junto ao CPF do Participante
                      do <strong>Experiência SW</strong> será a senha criada por
                      ele no momento do cadastro, podendo ser alterada a
                      qualquer momento por meio de acesso ao Site, no menu
                      “Minha Conta”.
                    </li>
                    <br />
                    <li>
                      O Participante do <strong>Experiência SW</strong> está
                      ciente de que jamais deverá fornecer a sua senha de acesso
                      ao Site a terceiros, sendo certo que o manuseio e guarda
                      desta senha são de sua total responsabilidade.
                    </li>
                    <br />
                    <li>
                      O e-mail cadastrado no <strong>Experiência SW</strong>{' '}
                      deve ser de titularidade do Participante não podendo ser
                      utilizado por outro Participante ou por qualquer terceiro.
                      O cadastro é para uso pessoal e intransferível, sendo
                      dever do Participante manter sob sua confidencialidade os
                      seus dados de acesso, não os compartilhando com terceiros,
                      independentemente do grau de parentesco ou amizade.
                    </li>
                    <br />
                    <li>
                      O Participante está ciente que é de sua inteira
                      responsabilidade a veracidade e a atualização dos dados
                      cadastrais informados ao Programa, devendo o Participante,
                      por medida de segurança, alterar sua senha junto ao
                      Programa periodicamente.
                      <br />
                      <br />
                      <ol>
                        <li>
                          A Promotora ou a Smart, poderão, a qualquer tempo,
                          solicitar o envio da documentação comprobatória dos
                          dados fornecidos pelo Participante no Programa, não
                          sendo, todavia, responsável por eventuais perdas ou
                          atrasos das correspondências.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      O acesso a área “Minha Conta” poderá ser realizado na
                      página inicial do Site ou diretamente pelo link:{' '}
                      <a href="www.experienciasw.com.br/minha-conta">
                        www.experienciasw.com.br/minha-conta
                      </a>
                      . Neste ambiente, também se poderá, entre outras
                      atividades, efetivar a alteração da senha pessoal
                      provisória inicial. Em caso de dúvidas ou advindo qualquer
                      nível de necessidade ou dificuldade, o Participante poderá
                      entrar em contato pelo telefone{' '}
                      <a href="tel:+5515997317809">(15) 99731-7809</a> ou pelo
                      e-mail:{' '}
                      <a href="mailto:contato@experienciasw.com.br">
                        contato@experienciasw.com.br
                      </a>
                      .
                    </li>
                    <br />
                    <li>
                      O Participante deverá manter os seus dados cadastrais
                      atualizados, sendo certo que a Promotora ou a Smart não
                      poderão ser responsabilizadas caso o Participante deixe de
                      receber algum benefício do Programa em virtude de
                      incorreção ou desatualização dos seus dados cadastrais.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  Na sequência ao cadastro, o Participante, deverá dar o aceite
                  ao presente Termos e Condições.
                </li>
                <br />
                <li>
                  O Participante, fará jus ao recebimento da pontuação advinda
                  da comercialização dos produtos da linha de fabricação da
                  Promotora nas Revendas Participantes e estabelecimentos
                  varejistas considerando-se as quantidades e especificidades
                  destes produtos, disponibilizados através do site, para fins
                  de recebimento de sua pontuação.
                </li>
                <br />
                <li>
                  Concluída a adesão ao <strong>Experiência SW</strong>, o
                  Participante terá acesso à sua conta do{' '}
                  <strong>Experiência SW</strong>, onde serão registrados os
                  Pontos, seus acúmulos e resgates e prazo de expiração dos
                  Pontos, a qual poderá ser acessada pelo site.
                </li>
                <br />
                <li>
                  Os Participantes somente serão beneficiados pelo Programa
                  desde que atendidas todas as condições descritas no presente
                  Termos e Condições.
                </li>
                <br />
                <li>
                  É faculdade da Promotora efetivar a exclusão ou inclusão de
                  Revendas que participarão do Programa criado, todavia, tais
                  fatos não interferirão nos direitos adquiridos pelos
                  Participantes, decorrentes das pontuações até então
                  existentes, mesmo que a Revenda tenha sido excluída por
                  qualquer motivo, inclusive inadimplemento.
                </li>
                <br />
                <li>
                  A participação no Programa Fidelidade é gratuita, sendo
                  necessária apenas a efetivação completa do cadastro do
                  participante e aceite deste Termos e Condições.
                </li>
                <br />
                <li>
                  A participação no Programa não caracteriza, sob nenhum aspecto
                  e/ou hipótese, vínculo empregatício, vínculo associativo ou a
                  constituição de qualquer modalidade de sociedade, parceria,
                  relação comercial, de representação ou qualquer outro vínculo,
                  à que título for, entre qualquer empresa relacionada ao
                  Programa e os Participantes, se não o expressamente descrito
                  no Termos e Condições.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li id="3">
              <strong>ACÚMULO DE PONTOS</strong>
              <br />
              <br />
              <ol>
                <li>
                  O <strong>Experiência SW</strong> conferirá Pontos aos seus
                  Participantes em razão da comercialização de um ou mais
                  Produtos Elegíveis. Deste modo, é essencial para o
                  funcionamento do <strong>Experiência SW</strong> que haja o
                  compartilhamento de dados pessoais dos Participantes entre a
                  Smart e a Promotora.
                  <br />
                  <br />
                  <ol>
                    <li>
                      Para entender mais sobre o tratamento de dados pessoais
                      sob o <strong>Experiência SW</strong>, o Participante
                      poderá consultar a Política de Privacidade, no site da
                      Promotora.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  A metodologia para o cálculo do número de pontos que serão
                  creditados aos Participantes será estabelecida por meio de
                  critério exclusivo da <strong>PROMOTORA</strong> e, terão como
                  base o produto comercializado pelo Participante.
                </li>
                <li>
                  Em nenhuma circunstância serão contabilizadas frações de
                  pontos.
                </li>
                <li>
                  ara que a pontuação seja computada e confirmada em seu favor,
                  após a efetivação da venda de um Produto Elegível, o
                  Participante deverá efetuar a captura da imagem digital da(s)
                  respectiva(s) nota(s) / cupom(ns) fiscal(is) e a transmissão
                  eletrônica da referida imagem por meio do Site.
                  <br />
                  <br />
                  <ol>
                    <li>
                      A imagem fotográfica da Nota Fiscal/Cupom Fiscal deve ser
                      capturada e transmitida de forma nítida, legível e
                      inequívoca, onde se possa identificar as suas principais
                      características como a quantidade, descrição de produtos,
                      número da nota/cupom fiscal, recibo e data da compra, pois
                      só serão aceitos comprovantes válidos e legíveis oriundos
                      das Revendas Participantes.
                    </li>
                    <br />
                    <li>
                      É de exclusiva responsabilidade do Participante guardar os
                      documentos fiscais originais dos Produtos vendidos (notas
                      fiscais e/ou faturas).
                      <br />
                      <br />
                      <ol>
                        <li>
                          Esses documentos deverão ser guardados, pelo
                          Participante, até a verificação dos lançamentos das
                          pontuações em seu extrato, bem como pelo respectivo
                          prazo de validade do crédito, sob pena de não poder
                          pleitear qualquer pontuação ou reclamação se acaso não
                          possuir tais documentos.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  As Revendas Participantes que tenham assiduidade e
                  habitualidade na comercialização dos Produtos, poderão
                  solicitar à Promotora ou à Smart, conforme o caso, a adoção de
                  um procedimento de pontuação automática em favor dos
                  Participantes vinculados a ela.
                  <br />
                  <br />
                  <ol>
                    <li>
                      Neste caso, as Revendas Participantes deverão encaminhar
                      ao Site a(s) nota(s) / cupom(ns) fiscal(is) concernentes
                      tão logo finalizadas as operações.
                    </li>
                    <br />
                    <li>
                      Os pontos serão então automaticamente computados em
                      benefício dos Participantes que comercializarem os
                      Produtos descritos na(s) nota(s) / cupom(ns) fiscal(is),
                      de acordo com a periodicidade diária, semanal, quinzenal
                      ou mensal, conforme previamente acordado com a Revenda
                      Participante.
                    </li>
                  </ol>
                </li>
                <li>
                  Os pontos obtidos pelos Participantes são cumuláveis e possuem
                  prazo de validade de 18 (dezoito meses) meses, que tem como
                  marco inicial de sua contagem a data do crédito de tais
                  pontos, pela Promotora, sendo obrigatório que a conta de
                  pontuação do Participante permaneça ativa no período.
                  <br />
                  <br />
                  <ol>
                    <li>
                      Caso após decorrido o prazo acima indicado não tenha o
                      Participante atingido a quantidade suficiente de pontos
                      para troca de produtos, os seus pontos serão
                      automaticamente expirados e declarados caducos, sem
                      qualquer aviso prévio.
                    </li>
                    <br />
                    <li>
                      O extrato de pontos poderá ser consultado no Site, no menu
                      “Pontuações”.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  O sistema de pontuação obedecerá às seguintes diretrizes:
                  <br />
                  <br />
                  <ul>
                    <li>
                      As pontuações são validadas e atribuídas em relação direta
                      ao volume de vendas de produtos da Promotora, realizadas
                      pelo Participante na Revenda Participante;
                      <li>Os pontos são pessoais e intransferíveis;</li>
                      <li>
                        A Smart se reserva ao direito da revisão dos pontos, até
                        90 (noventa) dias após a data de creditação, para
                        conferência e eventual correção sempre lastreada neste
                        Termos e Condições e nos princípios da boa fé;
                      </li>{' '}
                      <li>Otimização do catálogo de produtos;</li>
                      <li>
                        Os pontos obtidos no decorrer deste Programa poderão ser
                        utilizados de uma única vez ou de forma fracionada,
                        desde que respeitado o prazo de validade dos pontos e as
                        demais regras de resgate de produtos estabelecidas neste
                        Termos e Condições.
                      </li>
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <br />
            <li id="4">
              <strong>RESGATE DE PONTOS</strong>
              <br />
              <ol>
                <br />
                <li>
                  Os Pontos disponíveis no extrato do Participante permitirão
                  que ele obtenha produtos disponíveis no Catálogo de Produtos.
                </li>
                <br />
                <li>
                  Os Pontos necessários para a obtenção de descontos/resgate não
                  correspondem a um valor monetário, não podendo ser
                  convertidos, total ou parcialmente, em dinheiro.
                </li>
                <br />
                <li>
                  Os produtos e serviços listados no catálogo de produtos têm
                  caráter meramente indicativo e não representam a
                  disponibilidade do produto em estoque.
                </li>
                <br />
                <li>
                  O Programa poderá alterar, substituir ou cancelar os produtos
                  disponíveis a qualquer momento, sem necessidade de comunicação
                  ou anúncio prévio aos Participantes, sempre preservando os
                  direitos adquiridos dos Participantes que já pontuaram.
                </li>
                <br />
                <li>
                  A Promotora, segundo seu próprio critério, reserva-se o
                  direito de requerer um número mínimo de pontos para ter acesso
                  a determinados produtos do catálogo.
                </li>
                <br />
                <li>
                  Os resgates de produtos deverão ser realizados no Site,
                  mediante o uso de login e senha, devendo o Participante
                  indicar o(s) produtos(s) de sua preferência, dentre os
                  descritos no catálogo de produtos, e desde que possua o saldo
                  de pontos suficiente para o resgate do produto escolhido.
                </li>
                <br />
                <li>
                  Assim que o Participante realizar o resgate em produto, os
                  pontos utilizados serão automaticamente descontados de sua
                  conta após a confirmação por e-mail.
                </li>
                <br />
                <li>
                  Cada participante poderá realizar até 5 (cinco) resgates de produtos/experiências por mês. Sendo limitado a 1 resgate por parceiro/CNPJ.
                </li>
                <br />
                <li>
                  Em caso de falha sistêmica ou, em casos que um produto seja
                  resgatado em desacordo com a regra de pontuação vigente, por
                  qualquer motivo, os resgates serão automaticamente cancelados,
                  gerando o dever de devolução do produto por parte do
                  Participante, caso este já tenha realizado o resgate e
                  recebido o produto.
                  <br />
                  <br />
                  <ol>
                    <li>
                      Neste caso, o Participante será informado através do
                      contato telefônico no número de cadastro do premiado.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  O produto resgatado será entregue para o Participante através
                  de voucher digital disponibilizado nos endereços de contato
                  cadastrados conforme a indicação na solicitação de resgate de
                  pontuação, em até 60 (sessenta) dias, caso não seja
                  visualizado nenhuma irregularidade de pontuação e resgate.
                </li>
                <br />
                <li>
                  A confirmação da entrega dos produtos resgatados será feita
                  tacitamente, após o decurso do prazo indicado no item acima,
                  caso em que o formulário de resgate será considerado válido
                  como recibo assinado, ou mediante confirmação da entrega
                  realizada através dos canais de comunicação disponibilizados
                  pelo Participante.
                </li>
                <br />
                <li>
                  Caso o produto não seja entregue ao Participante em razão de
                  erro ou insuficiência de informações nos endereços de e-mail
                  ou comunicação cadastrados, o Participante deverá corrigir ou
                  complementar seu cadastro no Site para que uma nova tentativa
                  de entrega seja feita.
                  <br />
                  <br />
                  <ol>
                    <li>
                      Nesse caso, a nova tentativa de entrega ocorrerá em até 60
                      (sessenta) dias a contar da alteração das informações no
                      Site, observado o disposto no item anterior.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <br />
            <li id="5">
              <strong>CANCELAMENTO DO EXPERIÊNCIA SW</strong>
              <br />
              <br />
              <ol>
                <li>
                  O <strong>Experiência SW</strong> tem validade por tempo
                  indeterminado, entretanto, a Promotora poderá, a qualquer
                  momento, suspender o acúmulo de Pontos ou encerrar o{' '}
                  <strong>Experiência SW</strong>.
                  <br />
                  <br />
                  <ol>
                    <li>
                      o caso de ocorrência do previsto no item 5.1., o
                      Participante será avisado com antecedência de 30 (trinta)
                      dias sobre eventual suspensão ou cancelamento do{' '}
                      <strong>Experiência SW.</strong>
                    </li>
                    <br />
                    <li>
                      Uma vez encerrado/suspenso o{' '}
                      <strong>Experiência SW</strong>, eventuais pontos
                      acumulados poderão ser resgatados desde que respeitado o
                      prazo de validade destes.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  O Participante poderá, a qualquer tempo, solicitar o
                  cancelamento de sua participação no{' '}
                  <strong>Experiência SW</strong>, Via Central de Atendimento ou
                  por meio do Site.
                  <br />
                  <br />
                  <ol>
                    <li>
                      Após a solicitação de cancelamento, o Participante não
                      poderá resgatar seus pontos.
                    </li>
                    <br />
                    <li>
                      A Promotora esclarece que o Participante que solicitar o
                      cancelamento de sua participação no Programa não receberá,
                      por esse cancelamento, nenhuma classe de indenização ou
                      reparação.
                    </li>
                    <br />
                    <li>
                      Em caso de falecimento de um Participante seu cadastro
                      será desabilitado e os Pontos serão cancelados.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  A Promotora poderá alterar este Termos e Condições a qualquer
                  momento, sendo que divulgará as alterações no site, com
                  antecedência de 30 (trinta) dias corridos da data de início da
                  vigência da nova versão do Termos e Condições.
                  <br />
                  <br />
                  <li>
                    Caso o Participante não concorde com a alteração do Termos e
                    Condições, poderá revogar sua adesão ao{' '}
                    <strong>Experiência SW</strong>, deixando de fazer parte do
                    Programa, sem qualquer ônus. Desse modo, a permanência no
                    Programa implica em aceite às alterações efetivadas pela
                    Promotora.
                  </li>
                </li>
                <br />
                <li>
                  O Participante poderá ter sua participação no{' '}
                  <strong>Experiência SW</strong>
                  suspensa ou excluída, caso: (i) tenha prestado informações ou
                  declarações falsas à Promotora; (ii) infrinja as disposições
                  do Termos e Condições; (iii) utilize irregularmente o{' '}
                  <strong>Experiência SW</strong> contribuindo para a prática de
                  atos ilegais ou fraudulentos; (iv) compartilhe seus dados de
                  acesso à terceiros valendo-se de má-fé para o uso inadequado
                  de seus acessos; (v) pratique qualquer tipo de comercialização
                  de Pontos ou Produtos adquiridos pelo{' '}
                  <strong>Experiência SW</strong>; (iv) se valha de outras
                  hipóteses ora não elencadas que tenham como intenção a
                  utilização indevida do <strong>Experiência SW</strong> poderá
                  ser automaticamente suspenso ou excluído do Programa, sem
                  prejuízo das medidas legais cabíveis.
                  <br />
                  <br />
                  <p style={{ paddingLeft: 32 }}>
                    <p>
                      5.4.1.1. Na hipótese de suspensão, o prazo de validade dos
                      pontos acumulados continuará vigorando normalmente e na
                      hipótese de exclusão, o Participante não poderá usufruir
                      mais dos pontos já acumulados.
                    </p>
                    <p>
                      5.4.1.2. Com a finalidade de auditar o correto e efetivo
                      cumprimento deste Termos e Condições por parte do
                      Participante, a Promotora poderá, a qualquer momento,
                      segundo sua vontade e critério e sem prévio aviso, auditar
                      as contas dos Participantes. Durante o período em que essa
                      auditoria estiver sendo realizada, o Participante poderá
                      ser temporariamente suspenso do Programa.
                    </p>
                    <p>
                      5.4.1.3. Durante o prazo de suspensão ao Experiência SW, o
                      prazo de validade dos pontos correrá regularmente, com seu
                      vencimento na data original do Acúmulo.
                    </p>
                    <p>
                      5.4.1.4. Em caso de exclusão do Experiência SW, o
                      Participante terá o seu saldo de Pontos cancelado,
                      independentemente de serem adotadas as medidas judiciais
                      cabíveis.
                    </p>
                  </p>
                </li>
                <br />
                <li>
                  Cliente Participante também poderá esclarecer eventuais
                  dúvidas sobre o <strong>Experiência SW</strong> por meio do
                  Canal Fale Conosco no Site, após o seu login, ou por telefone{' '}
                  <a href="tel:+551533596265">(15) 3359-6265</a>,{' '}
                  <a href="https://wa.me/551151940191">
                    WhatsApp (11) 5194-0191
                  </a>{' '}
                  ou e-mail{' '}
                  <a href="mailto:contato@experienciasw.com.br">
                    contato@experienciasw.com.br
                  </a>
                  .
                </li>
                <br />
                <li>
                  Por possuírem caráter pessoal, inegociável e intransferível, é
                  vedada qualquer forma de cessão ou transferência dos pontos
                  acumulados, a qualquer tempo e título, a terceiros, sucessores
                  ou herdeiros.
                </li>
              </ol>
            </li>
            <br />
            <li id="6">
              <strong>POLÍTICA DE PROTEÇÃO DE DADOS PESSOAIS</strong>
              <br />
              <br />
              <ol>
                <li>
                  O <strong>Experiência SW</strong> tem o compromisso de
                  respeitar a privacidade e o sigilo das informações pessoais,
                  cadastrais, financeiras e dos demais dados acessados ou
                  compartilhados pelo Participante.
                </li>
                <br />
                <li>
                  A Smart coletará e tratará os Dados Pessoais (nos termos da
                  Lei 13.709/2018) dos Participantes com base na sua adesão ao
                  Programa, englobando o uso para os fins de cadastro no
                  Programa e a sua participação nele, incluindo, por exemplo, a
                  entrega de premiações. Com base nos interesses legítimos da
                  Smart, os dados pessoais dos Participantes poderão ser
                  utilizados, ainda, para fins de: (i) otimização do catálogo de
                  produtos do Programa com base no histórico de resgates dos
                  participantes (vedado o uso para outros programas de
                  benefícios); (ii) o desenvolvimento pela Smart de análise de
                  mercado, parâmetros e perfil de consumo de participantes, bem
                  como; (iii) outras atividades associadas ao bom funcionamento
                  e desenvolvimento do Programa.
                </li>
                <br />
                <li>
                  A Smart poderá solicitar foto, vídeo e/ou documento para a
                  estrita finalidade de validar e confirmar a identidade do
                  Participante e, assim, permitir o início ou a continuidade de
                  sua participação no Programa <strong>Experiência SW</strong>.
                </li>
                <br />
                <li>
                  A Smart responderá pelo cumprimento da LGPD - Lei Geral de
                  Proteção de Dados Pessoais (Lei nº 13.709/2018), inclusive
                  garantindo que os Dados Pessoais coletados deverão estar
                  armazenados em ambiente seguro e controlado da Smart ou de
                  terceiro por ela contratado e que não processará, tratará,
                  divulgará ou armazenará quaisquer Dados Pessoais se houver
                  razões para crer que tal processamento, tratamento, divulgação
                  ou armazenamento não tenha finalidade ou embasamento legal
                  adequados, ou que possa implicar infração de qualquer lei
                  aplicável.
                </li>
                <br />
                <li>
                  A Smart realizará esforços na adoção de medidas técnicas e
                  administrativas de segurança razoáveis para proteger os Dados
                  Pessoais de acessos não autorizados e de situações acidentais
                  ou ilícitas de destruição, perda, alteração, divulgação ou
                  qualquer forma de tratamento inadequado ou ilícito, realizando
                  as comunicações necessárias conforme previsto no art. 48 da
                  LGPD na eventualidade de tais incidentes, bem como eliminará
                  os Dados Pessoais de forma segura e irrecuperável, incluindo
                  de repositórios de terceiros, nos casos em que for obrigada
                  diante de solicitação de direitos ou quando houver o
                  encerramento da atividade de tratamento dos dados pessoais,
                  ressalvada a guarda necessária para cumprir obrigações
                  pendentes e para defesa de seus direitos.
                </li>
                <br />
                <li>
                  A Smart observará os direitos garantidos pelo art. 18 e demais
                  artigos da LGPD com relação ao Participante e eventuais outros
                  titulares de dados pessoais. O Participante poderá a qualquer
                  momento corrigir dados incompletos, inexatos ou desatualizados
                  informados em cadastro, por meio do menu “MINHA CONTA” após o
                  Login.
                </li>
                <br />
                <li>
                  A Promotora não realizará qualquer tratamento de Dados
                  Pessoais no contexto do Programa{' '}
                  <strong>Experiência SW</strong> e a Smart indenizará e manterá
                  a Promotora indene por qualquer responsabilidade ou
                  indenização decorrente da legislação brasileira de proteção de
                  dados pessoais.
                </li>
                <br />
                <li>
                  O <strong>Experiência SW</strong> não se responsabiliza pelo
                  mau uso do Participante, incluindo, mas não se limitando, às
                  hipóteses de perda, furto ou extravio, por qualquer meio ou
                  forma. O uso indevido dos dados de acesso ao{' '}
                  <strong>Experiência SW</strong>
                  será de exclusiva responsabilidade do Participante, incluindo
                  a responsabilidade por prejuízos em decorrência da utilização
                  indevida por terceiros, eximindo o{' '}
                  <strong>Experiência SW</strong> de qualquer responsabilidade
                  por tais atos.
                </li>
              </ol>
            </li>
            <br />
            <li id="7">
              <strong>CONSIDERAÇÕES GERAIS </strong> <br />
              <br />
              <ol>
                <li>
                  No primeiro acesso ao Site, o Participante deverá formalizar a
                  sua aceitação irrestrita aos termos deste Termos e Condições,
                  formalizando, portanto, a sua decisão de participar do
                  Programa. O Participante deverá e declarar ter amplo
                  conhecimento e concordar integralmente, de maneira plena e
                  irrestrita, com todos os termos e condições do{' '}
                  <strong>Experiência SW</strong>, como condição para que esteja
                  apto a usufruir os benefícios do Programa que lhe forem
                  aplicáveis.
                </li>
                <br />
                <li>
                  O aceite efetivado pelo Participante também se refere à
                  autorização para a captura de seus dados pessoais pela Smart
                  para a finalidade específica de participação neste Programa,
                  bem como para o compartilhamento de uso destes dados com a
                  Promotora e as empresas de seu grupo econômico, sempre para
                  fins relacionados aos interesses diretos dos titulares dos
                  dados.
                </li>
                <br />
                <li>
                  Caso o aceite não seja efetivado pelo Participante, a adesão
                  ao <strong>Experiência SW</strong> não será confirmada e,
                  portanto, o Usuário estará inapto para acumular pontos e
                  usufruir dos benefícios do Programa.
                </li>
                <br />
                <li>
                  A participação neste Programa implica na aceitação total de
                  todos os itens deste Termos e Condições, que poderá ser
                  alterado pela Promotora de forma unilateral, tantas vezes
                  quanto necessário, ao seu exclusivo critério,
                  independentemente de aviso ou divulgação prévia, preservando
                  sempre a pontuação adquirida pelo Participante que ainda
                  esteja válida, ainda que as condições de resgate possam ser
                  modificadas.
                </li>
                <br />
                <li>
                  No decorrer deste Programa, a Smart ou a Promotora poderão se
                  comunicar com o público-alvo, de forma próxima e direta,
                  utilizando-se dos principais canais de comunicação.
                </li>
                <br />
                <li>
                  Serão automaticamente desclassificados os Participante que
                  descumprirem os itens do presente Termos e Condições, agirem
                  de má-fé, de alguma forma burlarem as regras e condições deste
                  Termos e Condições, ou utilizarem mecanismos fraudulentos ou
                  ilícitos para obter vantagens indevidas no Programa.
                </li>
                <br />
                <li>
                  Se constatada fraude, má-fé ou correção de pontos nos casos em
                  que o produto já tenha sido entregue, a Smart poderá solicitar
                  a devolução do produto resgatado, negativação e ou abatimento
                  de pontos do usuário
                </li>
                <br />
                <li>
                  Não é permitida a participação de colaboradores da
                  Sherwin-Williams.
                </li>
                <br />
                <li>
                  É vedado a participação de usuários do Experiencia SW na
                  plataforma{' '}
                  <a href="http://clubepropintor.com.br">
                    http://clubepropintor.com.br
                  </a>{' '}
                  . Caso seja identificado o cadastro do usuário na plataforma,
                  será realizado o bloqueio.
                </li>
                <br />
                <li>
                  Os pontos creditados ao Participante não serão, em nenhuma
                  hipótese convertidos em dinheiro ou valores de qualquer outra
                  espécie, pois possuem caráter pessoal, inegociável e
                  intransferível.
                </li>
                <br />
                <li>
                  É vedado ao Participante qualquer tipo de comercialização ou
                  transferência da pontuação ou produtos adquiridos pelo
                  Programa, sob pena de exclusão do programa e/ou judiciais
                  cabíveis.
                </li>
                <br />
                <li>
                  Tendo em vista as características inerentes ao ambiente da
                  Internet, a Smart ou a Promotora não têm como garantir que o
                  acesso ao site do Programa, inclusive as promoções nele
                  eventualmente veiculadas, esteja livre de invasões,
                  interrupções ou suspensões, não inteiramente sujeitas ao seu
                  controle, se eximindo de qualquer responsabilidade proveniente
                  de tais fatos e/ou atos, o mesmo ocorrendo nas hipóteses de
                  força maior ou caso fortuito.
                </li>
                <br />
                <li>
                  Ao participar deste Programa, o Participante autoriza
                  expressamente o uso de seu nome e imagem pela Smart e pela
                  Promotora, para fins do disposto no art. 20 do Código Civil,
                  para divulgação do Programa, em todos os meios de comunicação,
                  pelo prazo máximo de 1 ano contado a partir da data da
                  finalização de seu cadastro no Programa, sem que isso traga
                  qualquer tipo de ônus para a empresa a Smart ou Promotora.
                </li>
                <br />
                <li>
                  A participação no <strong>Experiência SW</strong> é
                  voluntária, facultativa e gratuita, não havendo qualquer
                  sanção àqueles que optem por não participar.
                </li>
                <br />
                <li>
                  Quaisquer dúvidas relativas a este Programa poderão ser
                  solucionadas de segunda-feira a sexta-feira (exceto feriados)
                  das 9hs às 18hs, através dos seguintes canais de comunicação:
                  <br />
                  <br />
                  Canal Fale Conosco no Site (após Login) <br />
                  WhatsApp:{' '}
                  <a href="https://wa.me/551151940191">(11) 5194-0191</a>{' '}
                  <br />
                  Telefone: <a href="tel:+551533596265">(15) 3359-6265</a>{' '}
                  <br />
                  e-mail:{' '}
                  <a href="mailto:contato@experienciasw.com.br">
                    contato@experienciasw.com.br
                  </a>
                </li>
                <br />
                <li>
                  Caso a Smart tenha interesse na finalização do programa{' '}
                  <strong>Experiência SW</strong> em período anterior a sua data
                  limite, esta garantirá que todos os Participantes que
                  eventualmente ainda possuam saldo de pontos remanescentes,
                  recebam, por meio de entrega de “cartão presente” o valor
                  correlato ao objeto da pontuação.
                </li>
              </ol>
            </li>
            <br />
            <li id="8">
              <strong>REGISTRO</strong>
              <br />
              <br />
              <ol>
                <li>
                  Este Termos e Condições entra em vigor na data da sua
                  publicação em nossos canais.
                </li>
                <br />
                <li>
                  O presente Termos e Condições, bem como os seus respectivos
                  aditivos, será registrado no Cartório de Títulos e Documentos
                  no <strong>foro da Comarca de São Paulo/SP</strong>
                </li>
              </ol>
            </li>
          </ol>
        </p>
      </div>
    </Container>
  );
}
