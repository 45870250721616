import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import moment from 'moment-timezone';

export default function ModalAlterarDataEntrega({ show, onHide, resgate }) {
  const [dataEntrega, setDataEntrega] = useState('');
  const [salvando, setSalvando] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSalvando(true);

    try {
      await api.put(`/api/resgates/${resgate}/data-entrega`, {
        dataEntrega,
      });

      toast.success('Data de entrega alterada com sucesso!');
      onHide(true);
    } catch (error) {
      toast.error('Erro ao alterar data de entrega');
    } finally {
      setSalvando(false);
    }
  };

  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Alterar Data de Entrega</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="dataEntrega">Data de Entrega</label>
            <input
              type="date"
              className="form-control"
              id="dataEntrega"
              value={dataEntrega}
              onChange={(e) => setDataEntrega(e.target.value)}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onHide(false)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={salvando}
          >
            {salvando ? (
              <>
                <i className="fas fa-circle-notch fa-spin mr-2"></i>
                Salvando...
              </>
            ) : (
              'Salvar'
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
} 