import React from 'react';
import Login from '../../pages/Loja/Login';
import Home from '../../pages/Loja/Home';
import Loja from '../../pages/Loja/Loja';
import RotaLoja from './RotaLoja';
import EsqueceuSenha from '../../pages/Loja/EsqueceuSenha/index';
import Privacidade from '../../pages/Loja/Privacidade/index';
import Cadastrar from '../../pages/Loja/Cadastrar';
import CadastrarVendedor from '../../pages/Loja/CadastrarVendedor';

const rotas = [
  {
    path: '/',
    exact: true,
    component: Home,
    rotaPrivada: false,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    rotaPrivada: false,
  },
  {
    path: '/esqueceu-senha',
    exact: true,
    component: EsqueceuSenha,
    rotaPrivada: false,
  },
  {
    path: '/politica-privacidade',
    exact: true,
    component: Privacidade,
    rotaPrivada: false,
  },
  {
    path: '/esqueceu-senha/:uuid',
    component: EsqueceuSenha,
    rotaPrivada: false,
  },
  {
    path: '/cadastrar-vendedor/:idRevenda',
    component: CadastrarVendedor,
    rotaPrivada: false,
  },
  {
    path: '/cadastrar',
    component: Cadastrar,
    rotaPrivada: false,
  },
  {
    path: '/',
    component: Loja,
    rotaPrivada: true,
  },
];

const rotasLoja = rotas.map((rota, index) => (
  <RotaLoja
    key={index}
    path={rota.path}
    exact={rota.exact}
    component={rota.component}
    rotaPrivada={rota.rotaPrivada}
  ></RotaLoja>
));

export default rotasLoja;
