/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/API';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import { resgatarPremio } from '../../../store/modules/parceiro/actions';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { LoaderContext } from '../../Loader';
import { buildAddress } from '../../../utils';

export default function DetalhePremio() {
  const { setIsLoading } = useContext(LoaderContext);
  const dispatch = useDispatch();
  const { codigo } = useParams();
  const resgatando = useSelector((state) => state.parceiro.resgatando);
  const cartao = useSelector((state) => state.parceiro.cartao);
  const parceiro = useSelector((state) => state.parceiro);

  const [resgateLiberado, setResgateLiberado] = useState(null);
  const [premio, setPremio] = useState({
    nome: '',
    codigo: '',
    descricao: '',
    pontos: '',
    pontosAlternativo: '',
    diretorioFoto: '',
    tipo: '',
  });
  const [pontosBase, setPontosBase] = useState(0);
  const [qtde, setQtde] = useState(1);
  const [celularRecarga, setCelularRecarga] = useState('');
  const [operadoraRecarga, setOperadoraRecarga] = useState('');
  const [voltagem, setVoltagem] = useState('110V');
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [imgs, setImgs] = useState([]);

  const [confirmar, setConfirmar] = useState(false);

  const resgatar = async () => {
    try {
      setIsLoading(true);
      let obs = null;
      if (premio.tipo === 'recarga') {
        if (!operadoraRecarga) {
          toast.error('Selecione a operadora', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          });
          setConfirmar(false);
          return;
        }
        if (!celularRecarga) {
          toast.error('Informe o celular', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          });
          setConfirmar(false);
          return;
        }
        obs = `${operadoraRecarga} - ${celularRecarga}`;
      }

      if (premio.tipo === 'bivolt') {
        obs = voltagem;
      }

      if (premio.tipo === 'credito' && !cartao) {
        if (!nomeCompleto) {
          toast.error('Informe seu nome completo', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          });
          setConfirmar(false);
          return;
        }
        obs = nomeCompleto;
      }

      setConfirmar(false);
      dispatch(
        resgatarPremio({
          codigoPremio: premio.codigo,
          qtde,
          obs,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const alterarVoltagem = (valor) => {
    setVoltagem(valor);
    valor === '110V'
      ? setPontosBase(premio.pontos)
      : setPontosBase(premio.pontosAlternativo);
  };

  const addQtde = (e) => {
    e.preventDefault();
    setQtde(qtde + 1);
  };

  const subQtde = (e) => {
    if (qtde === 1) return;
    e.preventDefault();
    setQtde(qtde - 1);
  };

  const validarCelular = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (!valor) return;
    if (valor.length < 10) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCelularRecarga('');
    }
  };

  const buscarPremio = async () => {
    try {
      setIsLoading(true);
      const resultado = await api.get(`/api/tlc-offers/${codigo}`);
      if (resultado) {
        setPremio(resultado.data);
        setPontosBase(resultado.data.pontos);
        const temp = [];
        if (resultado.data.experience.mainImageUrl)
          temp.push(resultado.data.experience.mainImageUrl);
        if (resultado.data.partner.logoUrl)
          temp.push(resultado.data.partner.logoUrl);
        if (resultado.data.diretorioFoto)
          temp.push(resultado.data.diretorioFoto);
        setImgs(temp);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const dadosRevenda = async () => {
    const resposta = await api
      .get(`/api/revendas/cnpj/${parceiro.cnpjRevenda}`)
      .catch((err) => {
        console.log('Erro na consulta da revenda', err.message);
        setIsLoading(false);
      });
    if (resposta) {
      setResgateLiberado(resposta.data.resgateLiberado);
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNext = () => {
    if (currentIndex + 1 === imgs.length) setCurrentIndex(0);
    else setCurrentIndex((prev) => prev + 1);
  };
  const handlePrevious = () => {
    if (currentIndex === 0) setCurrentIndex(imgs.length - 1);
    else setCurrentIndex((prev) => prev - 1);
  };

  useEffect(() => {
    buscarPremio();
    dadosRevenda();
    setIsLoading(true);
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Breadcrumb>
        <a href="/home">CATEGORIA: {premio?.categoria?.nome}</a>
        <div style={{ padding: '0 16px' }}>{' > '}</div>
        <a href={`/experiencias?categoria=${premio?.categoria?.id}`}>
          EXPERIÊNCIA: {premio?.experience?.title}
        </a>
        <div style={{ padding: '0 16px' }}>{' > '}</div>
        <a
          href={`/loja?categoria=${premio?.categoria?.id}&experience=${premio?.experience?.id}`}
        >
          PRÊMIO: {premio?.nome}
        </a>
      </Breadcrumb>
      {!confirmar && !resgatando ? (
        <Row>
          <Col xs={12} lg={6} className="p-3 d-flex">
            <div
              id="carousel"
              class="carousel slide m-auto"
              style={{ width: '100%' }}
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src={imgs[currentIndex]}
                    class="d-block"
                    style={{ width: '100%' }}
                    alt={premio.nome}
                  />
                </div>
              </div>
              <button
                class="carousel-control-prev btn-slide"
                type="button"
                data-bs-target="#carousel"
                data-bs-slide="prev"
                onClick={handlePrevious}
              >
                <i className="fas fa-angle-left" style={{ fontSize: 32 }}></i>
              </button>
              <button
                class="carousel-control-next btn-slide"
                type="button"
                data-bs-target="#carousel"
                data-bs-slide="next"
                onClick={handleNext}
              >
                <i className="fas fa-angle-right" style={{ fontSize: 32 }}></i>
              </button>
            </div>
          </Col>

          <Col
            xs={12}
            lg={6}
            id="detalheProduto"
            name={premio.codigo}
            className="p-3"
          >
            <Col style={{ display: 'flex', gap: 32, alignItems: 'center ' }}>
              <span id="nomeProduto" className="premio-titulo">
                {premio.nome}
              </span>
            </Col>
            <br />

            <Col>
              <span className="premio-subtitulo">
                <b>Descrição</b>
              </span>
            </Col>
            <Col className="text-justify">
              <span
                className="d-inline-block premio-subtitulo"
                dangerouslySetInnerHTML={{ __html: premio.descricao }}
              ></span>
            </Col>
            <br />
            {/* {resgateLiberado !== 0 && (
              <Col className="text-justify">
                <button className="btn-sub-qtde m-1" onClick={subQtde}>
                  <i className="fas fa-minus"></i>
                </button>
                <span className="premio-subtitulo m-3">
                  <b>{qtde}</b>
                </span>
                <button className="btn-add-qtde m-1" onClick={addQtde}>
                  <i className="fas fa-plus"></i>
                </button>
              </Col>
            )} */}
            <br />
            {premio.tipo === 'recarga' && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="operadora">Operadora</label>
                  <select
                    id="operadora"
                    name="operadora"
                    placeholder="Operadora"
                    value={operadoraRecarga}
                    onChange={(e) => setOperadoraRecarga(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione a operadora
                    </option>
                    <option value="Vivo">Vivo</option>
                    <option value="Claro">Claro</option>
                    <option value="TIM">TIM</option>
                    <option value="OI">OI</option>
                    <option value="Nextel">Nextel</option>
                    <option value="Algar">Algar</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="celular">Celular</label>
                  <InputMask
                    id="celular"
                    name="celular"
                    type="text"
                    placeholder="Celular"
                    value={celularRecarga}
                    mask="(99) 99999-9999"
                    onChange={(e) => setCelularRecarga(e.target.value)}
                    onBlur={validarCelular}
                  ></InputMask>
                </Col>
              </form>
            )}
            {premio.tipo === 'bivolt' && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="voltagem">Voltagem</label>
                  <select
                    id="voltagem"
                    name="voltagem"
                    placeholder="Voltagem"
                    value={voltagem}
                    onChange={(e) => alterarVoltagem(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione a voltagem
                    </option>
                    <option value="110V">110V</option>
                    <option value="220V">220V</option>
                  </select>
                </Col>
              </form>
            )}

            {premio.tipo === 'credito' && !cartao && (
              <>
                <Col>
                  <span className="premio-subtitulo">
                    <b>Cartão Mastercard® Experiências SW</b>
                  </span>
                </Col>

                <Col className="text-justify">
                  <span>
                    Você ainda não possui um cartão Mastercard® Experiências SW.
                  </span>
                  <br />
                  <span>
                    Será enviado um envelope contendo o seu cartão Clube
                    Experiências SW, já com o valor deste resgate.
                  </span>
                  <br />
                  <span>
                    Após desbloquear seu cartão guarde-o para seus próximos
                    resgates. É permitido 1 (um) cartão por CPF.
                  </span>
                  <br />
                  <span>
                    Os próximos resgates em dinheiro, serão creditados
                    automaticamente neste mesmo cartão.
                  </span>
                  <br />
                  <span>
                    Por medida de segurança seu cartão estará bloqueado, após
                    receber o envelope, siga as instruções para desbloquear o
                    seu cartão.
                  </span>
                  <br />
                  <span>
                    O desbloqueio do seu cartão pode levar até 24h úteis após a
                    solicitação.
                  </span>
                  <br />
                  <span>
                    Em caso de perda ou roubo, solicite o bloqueio do seu
                    cartão.
                  </span>
                  <br />
                  <br />
                  <span>
                    Importante verificar se o nome digitado é o do titular do
                    CPF cadastrado. Caso o nome esteja incorreto, a MASTERCARD®
                    solicitará os dados novamente.
                  </span>
                  <br />
                  <br />
                </Col>

                <form className="form loja fundo-claro">
                  <Col className="d-flex flex-column" xs={8} sm={6}>
                    <label for="nome">Digite seu nome</label>
                    <input
                      type="text"
                      id="nomeCompleto"
                      placeholder="Nome Completo"
                      value={nomeCompleto}
                      onChange={(e) => setNomeCompleto(e.target.value)}
                    ></input>
                  </Col>
                </form>
              </>
            )}

            {premio.tipo === 'credito' &&
              cartao &&
              cartao.numero &&
              !cartao.entregue && (
                <>
                  <Col>
                    <span className="premio-subtitulo">
                      <b>Cartão Mastercard® Experiências SW</b>
                    </span>
                  </Col>

                  <Col className="text-justify">
                    <span>
                      SEU CARTÃO MASTERCARD® KG POINTS JÁ ESTÁ A CAMINHO.
                    </span>
                    <br />
                    <br />
                    <span>
                      Sua carga será depositada diretamente no cartão com final:{' '}
                      {cartao.numero.toString().slice(-4)}
                    </span>
                    <br />
                    <br />
                    <span>
                      Os próximos resgates em dinheiro serão creditados nesse
                      mesmo cartão. Continue resgatando normalmente.
                    </span>
                    <br />
                    <br />
                    <span>
                      Após desbloquear seu cartão guarde-o para seus próximos
                      resgates. É permitido 1 (um) cartão por CPF.
                    </span>
                    <br />
                    <br />
                    <span>Seu cartão é pessoal e intransferível.</span>
                  </Col>
                </>
              )}

            {premio.tipo === 'credito' &&
              cartao &&
              cartao.numero &&
              cartao.entregue && (
                <>
                  <Col>
                    <span className="premio-subtitulo">
                      <b>Cartão Mastercard® Experiências SW</b>
                    </span>
                  </Col>

                  <Col className="text-justify">
                    <span>
                      Você já possui um cartão Mastercard® Experiências SW
                    </span>
                    <br />
                    <br />
                    <span>
                      Sua carga será depositada diretamente no cartão com final:{' '}
                      {cartao.numero.toString().slice(-4)}
                    </span>
                    <br />
                    <br />
                    <span>
                      Após desbloquear seu cartão guarde-o para seus próximos
                      resgates. É permitido 1 (um) cartão por CPF.
                    </span>
                    <br />
                    <br />
                    <span>Seu cartão é pessoal e intransferível.</span>
                  </Col>
                </>
              )}
            <Col>
              <span className="premio-subtitulo" style={{ marginBottom: 8 }}>
                <b>{parseInt(premio.pontos)} pontos</b>
              </span>
            </Col>
            {resgateLiberado !== 0 && (
              <Col className="pt-5">
                <button
                  className="btn-resgatar"
                  onClick={() => setConfirmar(true)}
                >
                  <i className="fas fa-shopping-cart"></i> RESGATAR
                </button>
              </Col>
            )}
          </Col>
          <Col>
            <p style={{ fontSize: '20px', marginTop: 32 }}>
              <strong>Sobre este produto</strong>
            </p>
            {premio && (
              <div style={{ color: '#444' }}>
                <p>
                  <strong>Categoria</strong>
                </p>
                <ul>
                  <li className="list-item">{premio.categoria?.nome}</li>
                </ul>
                <p>
                  <strong>Sub-categoria</strong>
                </p>
                <ul>
                  <li className="list-item">{premio.subCategoria?.nome}</li>
                </ul>
                <p>
                  <strong>Experiência</strong>
                </p>
                <ul>
                  <li className="list-item">{premio.experience?.title}</li>
                  {premio.experience?.description && (
                    <li className="list-item">
                      {premio.experience.description}
                    </li>
                  )}
                </ul>
                <p>
                  <strong>Idade: </strong> De {premio?.ageFrom} até{' '}
                  {premio.ageTo} anos
                </p>

                <p>
                  <strong>Parceiro</strong>
                </p>
                {premio.partner?.logoUrl && (
                  <img
                    src={premio.partner?.logoUrl}
                    alt={premio.partner?.name}
                    style={{ width: 'auto', height: 100 }}
                  ></img>
                )}
                <ul>
                  <li className="list-item">{premio.partner?.name}</li>
                  <li className="list-item">{premio.partner?.description}</li>
                </ul>
                <p>
                  <strong>Endereço</strong>
                </p>
                <ul>
                  <li className="list-item">{buildAddress(premio.location)}</li>
                </ul>
                <p>
                  <strong>Contato</strong>
                </p>
                <ul>
                  <li className="list-item">
                    website -{' '}
                    <a
                      target="blank"
                      href={`https:${premio.location?.website}`}
                    >
                      {premio.location?.website}
                    </a>
                  </li>
                  <li className="list-item">
                    Telefone -{' '}
                    <a
                      href={`tel:${premio.location?.phoneNumber?.replace(
                        /[\()-\s]/g,
                        ''
                      )}`}
                    >
                      {premio.location?.phoneNumber}
                    </a>
                  </li>
                  <li className="list-item">
                    email -{' '}
                    <a href={`mailto:${premio.location?.email}`}>
                      {premio.location?.email}
                    </a>
                  </li>
                </ul>
                <p>
                  <strong>Termos</strong>
                </p>
                <ul>
                  <li className="list-item">{premio?.offerTerms}</li>
                </ul>
              </div>
            )}
          </Col>
        </Row>
      ) : null}
      {confirmar && !resgatando ? (
        <Row>
          <Col xs={12} lg={6} className="p-3">
            <img
              className="w-100"
              alt={premio.nome}
              src={premio.experience.mainImageUrl}
            ></img>
          </Col>
          <Col
            xs={12}
            lg={6}
            name={premio.codigo}
            className="d-flex justify-content-center align-items-center p-3 flex-column"
          >
            <span className="p-2 text-center">
              Tem certeza que deseja resgatar{' '}
              <span className="red">
                <b>
                  {`${qtde} x ${premio.nome} na ${premio.partner.name}${
                    buildAddress(premio.location) &&
                    `, localizado no Endereço ${buildAddress(premio.location)}`
                  }`}
                </b>
              </span>{' '}
              por{' '}
              <span className="red">
                <b>{Number(pontosBase * qtde).toLocaleString('pt-BR')}</b>{' '}
                pontos?
              </span>
            </span>
            <div className="text-center">
              <button className="btn btn-success m-2" onClick={resgatar}>
                <i className="fas fa-check"></i> SIM
              </button>
              <button
                className="btn btn-danger m-2"
                onClick={() => setConfirmar(false)}
              >
                <i className="fas fa-times"></i> NÃO
              </button>
            </div>
          </Col>
        </Row>
      ) : null}
      {resgatando && !confirmar ? (
        <Row>
          <Col xs={12} lg={6} className="p-3">
            <img
              className="w-100"
              alt={premio.nome}
              src={premio.diretorioFoto}
            ></img>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="d-flex justify-content-center align-items-center p-3 flex-column"
          >
            <span className="p-2">
              Resgatando <i className="fas fa-circle-notch fa-spin"></i>
            </span>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
}
